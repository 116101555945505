<template>
    <div :class="{ 'event-header': true, sticky: stickyHeader }">
        <StickyScrollElement
            v-if="stickyHeaderEnabled"
            :required-top-offset="stickyHeaderTopOffset"
            @updateStickyState="stickyHeader = $event"
        >
            <div class="sticky-header-wrapper">
                <div v-if="!isPresto" class="back-button">
                    <div class="back-button-container" @click="goBack">
                        <div class="back-button-icon">
                            <SvgIcon iconId="arrow_left" verticalAlign="unset" class="icon-size-very-small" />
                        </div>
                        <p class="back-button-title">
                            {{ $t('ui.common.back') }}
                        </p>
                    </div>
                </div>
                <EventHeaderCompact :participants="participants" />
                <div class="date-time-wrapper">
                    <slot name="date-time" />
                </div>
            </div>
        </StickyScrollElement>
        <div class="normal-header-wrapper" ref="normalHeaderWrapper">
            <div class="event-header-date-time">
                <slot name="date-time">
                    <span class="event-header-date badge">
                        <span>{{ getTime(event.startTime) }}</span>
                        &#32;
                        <span class="bold">{{ getDate(event.startTime) }}</span>
                    </span>
                </slot>
            </div>
            <div v-if="!isPresto" class="back-button">
                <div class="back-button-container" @click="goBack">
                    <div class="back-button-icon">
                        <SvgIcon iconId="arrow_left" verticalAlign="unset" class="icon-size-very-small" />
                    </div>
                    <p class="back-button-title">
                        {{ $t('ui.common.back') }}
                    </p>
                </div>
            </div>
            <div class="event-header-participants" v-if="participants.length">
                <div class="event-participant" v-for="(participant, index) in participants" :key="'participant-' + index">
                    <h1 class="event-participant-info event-participant-name">{{ participant.name }}</h1>
                    <h1
                        v-if="participant.score !== null"
                        class="event-participant-info event-participant-score"
                        :class="{ 'event-participant-score-live': isLive }"
                    >
                        {{ participant.score }}
                    </h1>
                </div>
            </div>
            <div class="event-header-details">
                <div class="event-breadcrumb">
                    {{ event.category.name }}
                    <span> / </span>
                    <router-link class="underline" :to="{ name: routeCountryName, params: { id: event.region.id } }">
                        {{ event.region.name }}
                    </router-link>
                    <span> / </span>
                    <router-link class="underline" :to="{ name: routeGroupName, params: { id: event.competition.id } }">
                        {{ event.competition.name }}
                    </router-link>
                </div>
                <div v-if="widgetId && !isPresto && !isLiveStreamAvailable" class="event-statistics" @click="onStatisticsClick">
                    <Badge v-if="showBadge" :text="$t('ui.common.new')" type="new" />
                    <span class="event-statistics-text">{{ $t('ui.common.statistics') }}</span>
                    <SvgIcon
                        :iconId="`icon-${getStatisticsIcon}`"
                        class="icon-statistics event-statistics-icon"
                        :class="{
                            'icon-statistics-open': isStatisticOpen,
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
import { auth, getter, action } from '@agi.packages/platform';
import { sport } from '@agi.packages/sport';
import { deviceType, helper } from '@agi.packages/core';
import { getEventWidget } from '@agi.packages/sport/utils/event/get-event-widget';
import Badge from '@/components/Badge.vue';
import { routeName } from '@/router/const-name';
import { mutation, getter as generalGetter } from '@/store/store';
import EventHeaderCompact from './EventHeaderCompact.vue';
import StickyScrollElement from './StickyScrollElement.vue';
import BackButtonMixin from '@/components/Pages/BackButton.mixin';

export default {
    name: 'EventHeader',
    mixins: [BackButtonMixin],
    components: {
        StickyScrollElement,
        Badge,
        EventHeaderCompact,
    },
    data() {
        return {
            routeCountryName: routeName.COUNTRY,
            routeGroupName: routeName.GROUP,
            isPresto: deviceType.isPresto(),
            stickyHeader: false,
            stickyHeaderTopOffset: 0,
        };
    },
    props: {
        event: {
            default: null,
        },
        marketId: [String, Number],
    },
    computed: {
        ...mapGetters({
            dateOptions: getter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            preference: getter.GET_PREFERENCE,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            country: getter.GET_COUNTRY,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
        }),
        ...mapState({
            isLiveStreamAvailable: (state) => state.sport.isLiveStreamAvailable,
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
        }),
        isEventEnd() {
            return !this.isLive && new Date(this.event.startTime).getTime() < new Date().getTime();
        },
        getStatisticsIcon() {
            const openIconState = this.isLive ? 'stadium' : 'statistics';
            return this.isStatisticOpen ? 'close' : openIconState;
        },
        participants() {
            const getScore = (index) => {
                if (this.isEventEnd || this.isLive) {
                    const score = index === 0 ? this.event.scoreboard?.matchScore.scoreHome : this.event.scoreboard?.matchScore.scoreAway;
                    return score || '-';
                }

                return null;
            };
            return this.event.participants?.map((participant, index) => ({ name: participant.name, score: getScore(index) })) || [];
        },
        widgetId() {
            const { eventId } = this.$route.query || {};
            return Number(eventId) || getEventWidget(this.event).id;
        },
        showBadge() {
            // remove it with BP-23155
            const NEW_BADGE_COUNT_LIMIT = 5;
            if (this.preference['new_badge_count'] >= NEW_BADGE_COUNT_LIMIT) {
                return false;
            }
            const badgeExpirationTime = new Date('2023-06-16').getTime();
            const isExpired = new Date().getTime() >= badgeExpirationTime;
            return !this.isStatisticOpen && !isExpired;
        },
        isLive() {
            return this.event?.additionalInfo.live;
        },
        isOtherCategory() {
            return Number(this.event.category.id) !== Number(this.currentCategoryId);
        },
        stickyHeaderEnabled() {
            return helper.getObjectField(this.brandPreference, 'isStickyHeaderEnabled', false) && !this.isPresto;
        },
    },
    methods: {
        ...mapActions({
            putNewBadgeCount: action.SET_NEW_BADGE_COUNT,
        }),
        ...mapMutations({
            toggleStatistic: sport.mutation.TOGGLE_STATISTIC,
        }),
        getTime(rawDate) {
            return helper.formatDateTime(rawDate, { needDate: false, ...this.dateOptions, isUtc: true });
        },
        getDate(rawDate) {
            return helper.formatDateTime(rawDate, { needTime: false, ...this.dateOptions, isUtc: true });
        },
        getRegionCode: helper.getRegionCode,
        onStatisticsClick() {
            this.$gtm.query({
                event: 'sport_radar_event_statistics_click',
                status: this.isStatisticOpen ? 'Closed' : 'Open',
                widget: this.event?.Live ? 'LMT' : 'H2H',
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
            });
            if (this.showBadge) {
                const value = {
                    key: 'new_badge_count',
                    value: this.preference['new_badge_count'] ? this.preference['new_badge_count'] + 1 : 1,
                };
                this.isAuthenticated && this.putNewBadgeCount(value);
            }
            this.toggleStatistic();
        },
    },
    mounted() {
        if (this.isOtherCategory) {
            this.$store.commit(mutation.SET_CURRENT_CATEGORY, this.event.category.id);
        }

        if (this.stickyHeaderEnabled) {
            this.stickyHeaderTopOffset = this.$refs.normalHeaderWrapper.getBoundingClientRect().height;
        }
    },
};
</script>

<style scoped lang="scss">
.event {
    &-header {
        width: 100%;
        color: #f4f5f0;
        background-color: $event-widget-dark-bg;
        border-color: $event-header-border-color-mobile;

        &-details {
            padding-bottom: 15px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include oldschool {
                font-size: 13px;
                line-height: 16px;
                margin-top: 8px;
            }
        }

        &-date-time {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 0 12px;
            font-size: 12px;
            line-height: 14px;
            color: inherit;
            text-align: center;
        }

        &-date {
            background: #353b40;
            color: inherit;
        }

        &-info {
            margin-left: 8px;
            line-height: 0;
            cursor: pointer;

            svg.event-info-icon {
                width: 16px;
                height: 16px;
                fill: $dark-green;
            }
        }

        &-participants {
            padding-top: 24px;
        }

        .sticky-header-wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: stretch;
            padding: 0 12px;
            opacity: 0;
            background: $brand-secondary-lighten-1;
            transition: transform 0.5s ease-in-out, opacity 1ms 0.5s;
            transform: translateY(-32px);

            ::v-deep .live-event-start-wrapper {
                width: auto;

                .live-event-start-time {
                    display: none;
                }

                .live-event-countdown {
                    position: static;
                }
            }

            .back-button {
                padding: 0;
                align-items: center;
                height: 31px;

                .back-button-title {
                    color: $light-grey;
                }

                .back-button-icon svg {
                    fill: $light-grey;
                }
            }

            .event-header-participants {
                margin: 0;
                padding: 0 8px;
            }

            .date-time-wrapper {
                min-width: 50px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        .normal-header-wrapper {
            padding: 0 12px;
        }

        &.sticky {
            position: relative;
            display: unset;
            padding: 0;

            &:before {
                // due to display: unset background won't be applied and it can be visible at some point when the screen is resized
                // this will add proper background color to the header, making it less visible
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $event-widget-dark-bg;
                content: '';
            }

            .sticky-header-wrapper {
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.5s ease-in-out, opacity 1ms;
            }
        }
    }

    &-breadcrumb {
        @extend %caption-font-400;
        cursor: pointer;
        color: $event-widget-dark-bg-text-color;

        @include oldschool {
            font-size: 13px;
            line-height: 16px;
        }
    }

    &-statistics {
        cursor: pointer;
        white-space: nowrap;
        margin-left: 16px;
        display: flex;
        align-items: center;

        &-text {
            @extend %small-details-font-400;
        }

        &-icon {
            margin-left: 6px;
        }
    }

    &-participant {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        & + & {
            margin-top: 6px;
        }

        &-info {
            margin: 0;
            @extend %h4-font-700;

            @include oldschool {
                font-size: 14px;
                line-height: 16px;
            }
        }

        &-score {
            margin-left: 12px;

            &-live {
                color: $event-live-score-color;
            }
        }
    }
}

.icon-statistics {
    &-open {
        padding: 2px;
    }
}

.back-button {
    position: relative;
    height: 40px;
    min-width: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 1;

    &-container {
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-icon {
        margin-right: 4px;

        svg {
            fill: $light-grey;
        }
    }
    &-title {
        @extend %link-small-font;
        color: $light-grey;
        line-height: 16px;
    }

    + .event-header-participants {
        margin-top: 2px;
        padding-top: 0;
    }
}
</style>
