import Vue from 'vue';
import { action as coreAction, actionLoader, helper, mutation as coreMutation, NETWORK_ERROR, userState } from '@agi.packages/core';

import { getLocalTypes } from '@/store/utils';
import { action as platformAction, auth, getter as platformGetter, mutation as platformMutation } from '@agi.packages/platform';
import { updateDepositOptions } from '@agi.packages/payment';

import endpoints from './endpoints';
import { depositStatus } from './payment-const';

export const action = {
    // DEPOSIT
    DEPOSIT: 'payment/deposit',
    DEPOSIT_IN_PROGRESS: 'payment/depositInProgress',
    POLL_DEPOSIT: 'payment/pollDeposit',
    FETCH_PROVIDERS_OPTIONS: 'payment/fetchProvidersOptions',
    FETCH_PROVIDER_DETAILS: 'payment/fetchProviderDetails',
    RESET_DEPOSIT: 'payment/resetDeposit',
    FETCH_DEPOSIT_TELCO_FEE: 'payment/fetchDepositTelcoFee',
    // PAYOUT
    WITHDRAW: 'payment/withdraw',
    CANCEL_PAYOUT: 'payment/cancelPayout',
    FETCH_PAYOUT_PROVIDERS: 'payment/fetchPayoutProviders',
    FETCH_PENDING_PAYOUTS: 'payment/fetchPendingPayouts',
    RESET_PAYOUT_STATUS: 'payment/resetPayoutStatus',
    RESET_PAYOUT_ERROR: 'payment/resetPayoutError',
    FETCH_PAYOUT_TAX_AMOUNT: 'payment/fetchPayoutTaxAmount',
};

export const mutation = {
    // DEPOSIT
    SET_DEPOSIT_DETAILS: 'payment/setDepositDetails',
    SET_DEPOSIT_OPTIONS: 'payment/setDepositOptions',
    SET_DEPOSIT_ERROR: 'payment/setDepositError',
    SET_DEPOSIT_INITIALIZED: 'payment/setDepositInitialized',
    SET_POLL_IN_PROGRESS: 'payment/setPollingInProgress',
    RESERVE_DEPOSIT_ID: 'payment/reserveDepositId',
    SET_PROVIDERS: 'payment/setProviders',
    SET_PROVIDERS_OPTIONS: 'payment/setProvidersOptions',
    SET_PROVIDER_DETAILS: 'payment/setProviderDetails',
    RESET_DEPOSIT: 'payment/resetDeposit',
    SET_DEPOSIT_TELCO_FEE: 'payment/setDepositTelcoFee',
    RESET_DEPOSIT_TELCO_FEE: 'payment/resetDepositTelcoFee',
    // PAYOUT
    SET_PAYOUT_PROVIDERS: 'payment/setPayoutProviders',
    SET_PAYOUT_STATUS: 'payment/setPayoutStatus',
    RESET_PAYOUT_ERROR: 'payment/resetPayoutError',
    RESET_PAYOUT_STATUS: 'payment/resetPayoutStatus',
    SET_EXPERIMENTAL_PAYOUTS: 'payment/setExperimentalPayouts',
    SET_PAYOUT_ERROR: 'payment/setPayoutError',
    SET_PENDING_PAYOUTS: 'payment/setPendingPayouts',
    SET_FIRST_DEPOSIT_DATA: 'payment/setFirstDepositData',
    SET_SELECTED_PROVIDER: 'payment/setSelectedProvider',
    SET_DEPOSIT_COMPONENT_IS_VISIBLE: 'payment/setDepositComponentIsVisible',
    SET_PAYOUT_TAX_AMOUNT: 'payment/setPayoutTaxAmount',
    RESET_PAYOUT_TAX_AMOUNT: 'payment/resetPayoutTaxAmount',
};

export const getter = {
    IS_WITHDRAW_WHT_DISABLED: 'payment/isWithdrawWhtDisabled',
    GET_FIRST_DEPOSIT_DATA: 'payment/getFirstDepositData',
    // DEPOSIT
    GET_PROVIDERS_OPTIONS: 'payment/getProvidersOptions',
    GET_PROVIDERS_LIST: 'payment/getProvidersList',
    GET_PROVIDER_DETAILS: 'payment/getProviderDetails',
    GET_DEPOSIT_INIT: 'payment/getDepositInit',
    GET_DEPOSIT_OPTIONS: 'payment/getDepositOptions',
    GET_POLL_IN_PROGRESS: 'payment/getPollInProgress',
    GET_RESERVED_DEPOSIT: 'payment/getReservedDeposit',
    GET_DEPOSIT_DETAILS: 'payment/getDepositDetails',
    GET_DEPOSIT_ERROR: 'payment/getDepositError',
    IS_DEPOSIT_TELCO_FEE_ENABLED: 'payment/isDepositTelcoFeeEnabled',
    // PAYOUT
    GET_PAYOUT_PROVIDERS: 'payment/getPayoutProviders',
    GET_PAYOUT_STATUS: 'payment/getPayoutStatus',
    GET_PENDING_PAYOUTS: 'payment/getPendingPayouts',
    GET_PAYOUT_ERROR: 'payment/getPayoutError',
    GET_SELECTED_PROVIDER: 'payment/getSelectedProvider',
    GET_IS_DEPOSIT_COMPONENT_VISIBLE: 'payment/getIsDepositComponentVisible',
    GET_OPTIONS_UUID: 'payment/getOptionsUUID',
    SHOW_LINK: 'payment/showLink',
};

const INITIAL_DEPOSIT_STATUS = {
    isInitialized: false,
    details: {
        CustomerEmail: '',
        CustomerIdentifier: '',
        DepositInitId: null,
        FeeAmount: null,
        GrossAmount: null,
        NetAmount: null,
        StatusText: '',
        StatusType: null,
    },
};

const INITIAL_DEPOSIT_TELCO_FEE = {
    feeAmount: undefined,
    compensationAmount: undefined,
    totalAmount: undefined,
};

const INITIAL_PAYOUT_STATUS = {
    success: null,
    error: null,
    cmsId: null,
};

const state = {
    // TODO: merge `payout.status.error` and `payout.error`
    payout: {
        status: { ...INITIAL_PAYOUT_STATUS },
        error: null,
        pending: [],
        providers: null,
        isExperimental: null,
        taxAmount: undefined,
    },
    deposit: {
        status: { ...INITIAL_DEPOSIT_STATUS },
        options: {},
        error: null,
        pollInProgress: null,
        pollInterval: 2000,
        providers: {
            list: [],
            options: [],
            details: {},
            selectedId: null,
            isDepositComponentVisible: true,
            optionsForUserUuid: '',
        },
        telcoFee: { ...INITIAL_DEPOSIT_TELCO_FEE },
    },
    reservedDeposit: {},
    /**
     * @deprecated since v3.14.x - use `firstDeposit` instead.
     */
    lastDepositProvider: null,
    // temporary solution, was updated by https://aliengain.atlassian.net/browse/BP-19497
    firstDeposit: {
        userId: null,
        providerName: null,
    },
};

const getters = {
    getFirstDepositData: (state) => state.firstDeposit,
    // DEPOSIT
    getProvidersOptions: (state, getters, rootState, rootGetters) => {
        // TODO: need to add ussdPage and ussdText fields to Strapi and remove the hardcoded template for pageUrl and text fields
        // (this can be used as a fallback for some time, like {..., pageUrl:Provider.ussdPageUrl || `${provider.pageUrl} -ussd`, ... })
        const additionalProviderOption = ({ provider, id }) => {
            return {
                id,
                depositTypeName: provider.depositTypeName,
                paymentProvider: provider.paymentProvider,
                applicableForUserTelco: provider.applicableForUserTelco,
                text: `${provider.text || ''} USSD`,
                mediaUrl: provider.mediaUrl,
                showLink: true,
                pageUrl: `${provider.pageUrl}-ussd`,
                channel: provider.channel,
            };
        };

        const additionalOptions = getters.getProvidersList
            .filter((option) => !!option.ussdAvailable)
            .map((sProvider, index) => {
                return additionalProviderOption({ provider: sProvider, id: `provider-${index}` });
            });

        return [...getters.getProvidersList.filter((option) => !!option.webAvailable), ...additionalOptions];
    },
    // deposit list page
    getProvidersList: (state, getters, rootState, rootGetters) =>
        updateDepositOptions({
            optionsConfig: rootGetters[platformGetter.GET_DEPOSIT_OPTIONS_CONFIG],
            options: state.deposit.providers.options,
        }),
    getSelectedProvider: (state, getters) => {
        return getters.getProvidersOptions.find(({ id }) => id === state.deposit.providers.selectedId) || {};
    },
    getProviderDetails: (state) => (id) => state.deposit.providers.details[id],
    getReservedDeposit: (state) => state.reservedDeposit,
    getPollInProgress: (state) => state.deposit.pollInProgress,
    getDepositOptions: (state) => state.deposit.options,
    getDepositDetails: (state) => state.deposit.status.details,
    getDepositInit: (state) => state.deposit.status.isInitialized,
    getDepositError: (state) => state.deposit.error,
    // PAYOUT
    getPayoutProviders: (state) => state.payout.providers,
    getPayoutStatus: (state) => state.payout.status,
    getPendingPayouts: (state) => state.payout.pending,
    getPayoutError: (state) => state.payout.error,
    getIsDepositComponentVisible: (state) => state.deposit.providers.isDepositComponentVisible,
    getOptionsUUID: (state) => state.deposit.providers.optionsForUserUuid,
    showLink: (state, getters) => {
        return !!getters.getSelectedProvider?.showLink;
    },
    isWithdrawWhtDisabled: (state, getters, rootState, rootGetters) => {
        const { isWithdrawWhtDisabled } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return isWithdrawWhtDisabled === true;
    },
    isDepositTelcoFeeEnabled: (state, getters, rootState, rootGetters) => {
        return (
            !!rootGetters[platformGetter.GET_BRAND_PREFERENCE]?.isDepositTelcoFeeEnabled &&
            !!getters.getSelectedProvider?.feeStructure?.length
        );
    },
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    // DEPOSIT
    [_mutation.SET_DEPOSIT_DETAILS](state, value) {
        state.deposit.status.details = { ...state.deposit.status.details, ...value };
    },
    [_mutation.SET_DEPOSIT_OPTIONS](state, value = {}) {
        state.deposit.options = value;
        state.deposit.status.isInitialized = true;
        state.deposit.error = null;
    },
    [_mutation.SET_DEPOSIT_INITIALIZED](state) {
        state.deposit.status.isInitialized = true;
    },
    [_mutation.SET_PROVIDERS](state, value) {
        state.deposit.providers.list = value;
    },
    [_mutation.SET_PROVIDERS_OPTIONS](state, value) {
        const { options, uuid } = value || {};
        state.deposit.providers.options = options;
        state.deposit.providers.optionsForUserUuid = uuid;
    },
    [_mutation.SET_PROVIDER_DETAILS](state, value) {
        const { details, id } = value;
        Vue.set(state.deposit.providers.details, id, details);
    },
    [_mutation.RESET_DEPOSIT](state) {
        state.deposit.status = { ...state.deposit.status, ...INITIAL_DEPOSIT_STATUS };
        state.reservedDeposit = {};
    },
    [_mutation.SET_DEPOSIT_ERROR](state, value) {
        state.deposit.error = value;
    },
    [_mutation.SET_POLL_IN_PROGRESS](state, value) {
        state.deposit.pollInProgress = value;
    },
    [_mutation.RESERVE_DEPOSIT_ID](state, value) {
        state.reservedDeposit = value;
    },
    // PAYOUT
    [_mutation.SET_PAYOUT_PROVIDERS](state, value) {
        state.payout.providers = value;
    },
    [_mutation.SET_PAYOUT_STATUS](state, value) {
        state.payout.status = { ...INITIAL_PAYOUT_STATUS, ...value };
    },
    [_mutation.RESET_PAYOUT_STATUS](state) {
        state.payout.status = { ...INITIAL_PAYOUT_STATUS };
    },
    [_mutation.SET_EXPERIMENTAL_PAYOUTS](state, value) {
        state.payout.isExperimental = value;
    },
    [_mutation.SET_PAYOUT_ERROR](state, value) {
        state.payout.error = value;
    },
    [_mutation.SET_PENDING_PAYOUTS](state, value) {
        state.payout.pending = value;
    },
    [_mutation.RESET_PAYOUT_ERROR](state) {
        state.payout.error = null;
    },
    [_mutation.SET_FIRST_DEPOSIT_DATA](state, { userId, providerName }) {
        state.firstDeposit = { userId, providerName };
    },
    [_mutation.SET_SELECTED_PROVIDER](state, value) {
        state.deposit.providers.selectedId = value;
    },
    [_mutation.SET_DEPOSIT_COMPONENT_IS_VISIBLE](state, value) {
        state.deposit.providers.isDepositComponentVisible = value;
    },
    [_mutation.SET_PAYOUT_TAX_AMOUNT](state, value) {
        state.payout.taxAmount = value;
    },
    [_mutation.RESET_PAYOUT_TAX_AMOUNT](state) {
        state.payout.taxAmount = undefined;
    },
    [_mutation.SET_DEPOSIT_TELCO_FEE](state, { feeAmount, compensationAmount, totalAmount }) {
        state.deposit.telcoFee.feeAmount = feeAmount;
        state.deposit.telcoFee.compensationAmount = compensationAmount;
        state.deposit.telcoFee.totalAmount = totalAmount;
    },
    [_mutation.RESET_DEPOSIT_TELCO_FEE](state) {
        state.deposit.telcoFee = { ...INITIAL_DEPOSIT_TELCO_FEE };
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.DEPOSIT]: actionLoader(
        action.DEPOSIT,
        (
            { commit, dispatch },
            { Amount, polling = true, providerName, depositComponentWithSelect, providerLabel, depositSuccessfulUrl, depositFailedUrl }
        ) => {
            commit(_mutation.SET_DEPOSIT_OPTIONS, { providerName, Amount, polling });
            return Vue.$http
                .post(
                    endpoints.createDeposit,
                    {
                        amount: Amount,
                        depositPaymentSystemName: providerName,
                        depositSuccessfulUrl,
                        depositFailedUrl,
                    },
                    { translationParams: { provider: providerLabel } }
                )
                .then(({ data }) => {
                    commit(_mutation.SET_DEPOSIT_DETAILS, data);
                    if (polling) {
                        commit(_mutation.SET_POLL_IN_PROGRESS, true);
                        dispatch(_action.POLL_DEPOSIT, {
                            ...data,
                            Amount,
                            providerName,
                            depositComponentWithSelect,
                            providerLabel,
                            step: 'CREATE',
                        });
                    }
                })
                .catch((error) => {
                    commit(_mutation.RESET_DEPOSIT);
                    commit(_mutation.SET_DEPOSIT_ERROR, helper.processErrorResponse(error, 'ui.common.error.failedToDeposit'));
                    Vue.$gtm.query({
                        event: 'deposit_failed',
                        method: providerName,
                        deposit_amount: Amount,
                        reason: error.message || 'unknown',
                        depositComponentWithSelect,
                    });
                });
        }
    ),
    [_action.POLL_DEPOSIT]: actionLoader(action.POLL_DEPOSIT, ({ state, commit, dispatch, rootGetters }, payload) => {
        const { DepositInitId, inProgress, Amount, providerName, depositComponentWithSelect, providerLabel, step, error } = payload;
        if (!DepositInitId) {
            Vue.$sentry.withScope((scope) => {
                scope.setExtras({ payload });
                if (error) scope.setExtras({ error });
                scope.setTag('scope', 'deposit');
                scope.setTag('polling', 'error');
                scope.setLevel('fatal');
                Vue.$sentry.captureMessage(`FATAL_ERROR_POLL_DEPOSIT_${step || 'UNKNOWN_STEP'}`);
            });
            commit(_mutation.RESET_DEPOSIT);
            commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
            commit(_mutation.SET_DEPOSIT_ERROR, helper.processErrorResponse(error, 'ui.common.error.failedToDeposit'));
            return Promise.resolve(false);
        }
        if (!inProgress) {
            commit(coreMutation.START_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
        }
        if (!state.deposit.pollInProgress) {
            return Promise.resolve(state.deposit.pollInProgress);
        }
        return Vue.$http
            .get(`${endpoints.getDeposit}/${DepositInitId}`, { translationParams: { provider: providerLabel } })
            .then(function ({ data }) {
                commit(_mutation.SET_DEPOSIT_DETAILS, data);
                switch (data.StatusType) {
                    case depositStatus.INIT:
                    case depositStatus.PENDING:
                    case depositStatus.AWAITING_USER_CONFIRMATION:
                        setTimeout(() => {
                            dispatch(_action.POLL_DEPOSIT, {
                                DepositInitId,
                                inProgress: true,
                                Amount,
                                providerName,
                                depositComponentWithSelect,
                                providerLabel,
                                step: 'ATTEMPT',
                            });
                        }, state.deposit.pollInterval);
                        break;
                    case depositStatus.SUCCESS:
                        const currentUserStatus = rootGetters[platformGetter.GET_CURRENT_USER_STATUS];
                        const isEverDeposited = currentUserStatus[userState.EVER_DEPOSITED];
                        const { userUuid: userId } = rootGetters[platformGetter.GET_USER_SETTINGS];
                        const preference = rootGetters[platformGetter.GET_PREFERENCE];

                        if (!preference.deposit_option) {
                            commit(_mutation.SET_FIRST_DEPOSIT_DATA, { providerName, userId });
                        }
                        Vue.$gtm.query({
                            event: 'deposit',
                            method: providerName,
                            first_transaction: !isEverDeposited,
                            deposit_amount: Amount,
                            depositComponentWithSelect,
                        });
                        commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
                        break;
                    // check if still relevant, may have been covered by error codes
                    case depositStatus.FAILED:
                    case depositStatus.INSUFFICIENT_FUNDS:
                    case depositStatus.NOT_REGISTERED:
                    case depositStatus.AMOUNT_TOO_SMALL:
                    case depositStatus.GENERAL_FAILURE:
                        commit(_mutation.RESET_DEPOSIT);
                        commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
                        commit(_mutation.SET_DEPOSIT_ERROR, data.StatusText);
                        commit(_mutation.SET_DEPOSIT_DETAILS, data);
                        Vue.$gtm.query({
                            event: 'deposit_failed',
                            method: providerName,
                            deposit_amount: Amount,
                            reason: data.StatusText,
                            depositComponentWithSelect,
                        });

                        break;
                    default:
                        commit(_mutation.RESET_DEPOSIT);
                        commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
                        break;
                }
            })
            .catch((error) => {
                if (error.errorCode === NETWORK_ERROR) {
                    setTimeout(() => {
                        dispatch(_action.POLL_DEPOSIT, {
                            DepositInitId,
                            inProgress: true,
                            Amount,
                            providerName,
                            depositComponentWithSelect,
                            providerLabel,
                            error,
                            step: 'CATCH',
                        });
                    }, state.deposit.pollInterval);
                    return;
                }
                commit(_mutation.RESET_DEPOSIT);
                commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
                commit(_mutation.SET_DEPOSIT_ERROR, helper.processErrorResponse(error, 'ui.common.error.failedToDeposit'));

                Vue.$gtm.query({
                    event: 'deposit_failed',
                    method: providerName,
                    deposit_amount: Amount,
                    reason: error.message || 'unknown',
                    depositComponentWithSelect,
                });
            });
    }),
    [_action.RESET_DEPOSIT]({ commit }) {
        commit(_mutation.RESET_DEPOSIT);
    },
    [_action.FETCH_PROVIDERS_OPTIONS]: actionLoader(action.FETCH_PROVIDERS_OPTIONS, ({ commit, dispatch, getters, rootGetters }) => {
        const { userUuid } = rootGetters[platformGetter.GET_USER_SETTINGS];
        const optionsUserUuid = getters.getOptionsUUID;

        return dispatch(
            coreAction.LOADER,
            [
                optionsUserUuid !== userUuid ? {} : getters.getProvidersOptions,
                (resolve, reject) =>
                    Vue.$http
                        .get(endpoints.getDepositOptions)
                        .then(({ data }) => {
                            commit(_mutation.SET_PROVIDERS_OPTIONS, { options: data.data, uuid: userUuid });
                            resolve(data.data);
                        })
                        .catch((error) => reject(error)),
            ],
            { root: true }
        );
    }),
    [_action.FETCH_PROVIDER_DETAILS]: actionLoader(action.FETCH_PROVIDER_DETAILS, ({ commit, dispatch, getters }, id) => {
        return dispatch(
            coreAction.LOADER,
            [
                getters.getProviderDetails(id),
                (resolve, reject) =>
                    Vue.$http
                        .get(`${endpoints.getDepositTypeDetails}/${id}`)
                        .then(({ data }) => data)
                        .then(({ limit, data, status }) => {
                            const details = { limit, ...data, status };
                            commit(_mutation.SET_PROVIDER_DETAILS, { id, details });
                            resolve(details);
                        })
                        .catch((error) => reject(error)),
            ],
            { root: true }
        );
    }),
    // PAYOUT
    [_action.WITHDRAW]: actionLoader(action.WITHDRAW, ({ commit, dispatch, rootGetters }, payload) => {
        const isExperimental = state.payout.isExperimental;
        return dispatch(
            coreAction.WATCHER,
            [
                () => rootGetters[platformGetter.IS_KYC_VERIFIED_OR_BYPASS],
                ({ kycbypass, kycverified }) => {
                    if (kycbypass || kycverified) {
                        return Vue.$http
                            .post(isExperimental ? endpoints.withdrawDisabled : endpoints.withdraw, payload)
                            .then(({ data }) => {
                                const { CmsId, Status } = data.data || data.Data;
                                if (CmsId) {
                                    dispatch(platformAction.GET_TEXT, [CmsId], { root: true });
                                }
                                switch (Status) {
                                    case 'UNKNOWN':
                                        commit(_mutation.SET_PAYOUT_STATUS, {
                                            success: Vue.$t('ui.payment.payout.error.unknown'),
                                            cmsId: CmsId,
                                        });
                                        break;
                                    case 'ACCEPTED':
                                    case 'SUCCESS':
                                    case 'SENDING':
                                    case 'QUEUED':
                                        commit(_mutation.SET_PAYOUT_STATUS, {
                                            success: Vue.$t('ui.payment.payout.error.success'),
                                            cmsId: CmsId,
                                        });
                                        break;
                                    case 'REJECTED':
                                    case 'FAILURE':
                                    case 'PAID_MANUALLY':
                                    case 'WAITING_ACCEPTANCE':
                                    case 'REMOVE_FROM_QUEUE':
                                    default:
                                        commit(_mutation.SET_PAYOUT_STATUS, {
                                            error: Vue.$t('ui.payment.payout.error.other'),
                                            cmsId: CmsId,
                                        });
                                        break;
                                }

                                const trackingData = {
                                    event: 'withdraw',
                                    amount_withdrawn: payload.amount,
                                    method: payload.payoutType,
                                };
                                dispatch(auth.action.GET_BALANCE, { trackingData, force: true }, { root: true });
                                dispatch(_action.FETCH_PENDING_PAYOUTS);
                                dispatch(_action.FETCH_PAYOUT_PROVIDERS);
                            })
                            .catch((error) => {
                                commit(_mutation.SET_PAYOUT_STATUS, {
                                    error: helper.processErrorResponse(error, 'ui.common.error.failedToWithdraw'),
                                });
                                Vue.$gtm.query({
                                    event: 'withdraw_failed',
                                    amount_withdrawn: payload.amount,
                                    method: payload.payoutType,
                                    reason: error.message || 'unknown',
                                });
                            });
                    } else {
                        return new Promise((resolve) => resolve());
                    }
                },
                null,
                ({ kycbypass, kycverified }) => {
                    // Callback to Reset KYC values to initial state
                    // In case of `bypass == true`  and `success == false`,
                    // system should just allow the withdrawal to continue
                    // and act like KYC was never requested.
                    // Next withdrawal should trigger verification again.
                    ((kycbypass && !kycverified) || (!kycbypass && !kycverified)) &&
                        commit(platformMutation.RESET_KYC, null, { root: true });
                },
            ],
            { root: true }
        );
    }),
    [_action.CANCEL_PAYOUT]: actionLoader(action.CANCEL_PAYOUT, ({ commit, dispatch }, withdrawalUuid) => {
        dispatch(_action.RESET_PAYOUT_ERROR);
        dispatch(_action.RESET_PAYOUT_STATUS);
        return Vue.$http
            .post(endpoints.cancelPayout, { withdrawalUuid })
            .then(() => {
                dispatch(auth.action.GET_BALANCE, { force: true }, { root: true });
                dispatch(_action.FETCH_PENDING_PAYOUTS);
                dispatch(_action.FETCH_PAYOUT_PROVIDERS).then(() => {
                    dispatch(_action.RESET_PAYOUT_STATUS);
                });
                Vue.$gtm.query({ event: 'payout_canceled' });
            })
            .catch((error) => {
                commit(_mutation.SET_PAYOUT_ERROR, helper.processErrorResponse(error, 'ui.common.error.failedToCancelPayout'));
            });
    }),
    [_action.FETCH_PENDING_PAYOUTS]({ commit, dispatch }) {
        dispatch(_action.RESET_PAYOUT_ERROR);
        return Vue.$http
            .get(endpoints.getPendingPayouts)
            .then(({ data }) => {
                const payouts = data.data.reduce(
                    (result, item) => [
                        ...result,
                        ...[
                            {
                                date: item.createdIsoInstant,
                                id: item.uuid,
                                amountUnformatted: item.amountUnformatted,
                            },
                        ],
                    ],
                    []
                );
                commit(_mutation.SET_PENDING_PAYOUTS, payouts);
            })
            .catch((error) => {
                commit(_mutation.SET_PAYOUT_ERROR, helper.processErrorResponse(error, 'ui.common.error.failedPendingPayouts'));
            });
    },
    [_action.FETCH_PAYOUT_PROVIDERS]: actionLoader(action.FETCH_PAYOUT_PROVIDERS, ({ state, commit, dispatch, rootGetters }) => {
        const isUganda = rootGetters[platformGetter.COUNTRY_CODE_IS].UG;
        const isExperimental = state.payout.isExperimental;
        return Vue.$http
            .get(isExperimental ? endpoints.getPayoutTypeListDisabled : endpoints.getPayoutTypeList)
            .then(({ data }) => {
                const { payoutMethods, successfulPayoutsToday, thisWeeksSuccessfulPayoutsCount } = data.data;
                const providers = payoutMethods.reduce(
                    (list, provider) => [
                        ...list,
                        ...[
                            {
                                ...(isUganda && {
                                    documentNumber: provider.DocumentNumber,
                                    nin: provider.NationalIdentificationNumber,
                                    nationality: provider.Nationality,
                                    isPersonalDataSet: provider.isPersonalDataSet,
                                }),
                                maxAmount: provider.maxAmount,
                                minAmount: provider.minAmount,
                                firstName: provider.FirstName || '',
                                lastName: provider.LastName || '',
                                region: provider.state,
                                isNameSet: !!(provider.FirstName && provider.LastName),
                                payoutType: provider.PayoutType,
                                paymentProvider: provider.paymentProvider,
                                name: provider.PayoutTypeName,
                                strategy: provider.PayoutTypeStrategy,
                                banks: provider.ExtraData.Banks,
                                previous: provider.ExtraData.PreviousBankAccount,
                                cmsId: provider.CmsComponentId,
                                channel: provider.channel,
                                status: provider.status,
                                freeWithdrawalsCount24h: provider.freeWithdrawalsCount24h,
                                freeWithdrawalsCountPerWeek: provider.freeWithdrawalsCountPerWeek,
                                percentageFee: provider.percentageFee,
                                flatFee: provider.flatFee,
                                successfulPayoutsToday,
                                thisWeeksSuccessfulPayoutsCount,
                                fractionalSupported: provider.fractionalSupported,
                            },
                        ],
                    ],
                    []
                );
                commit(_mutation.SET_PAYOUT_PROVIDERS, providers);
                dispatch(_action.FETCH_PENDING_PAYOUTS);
            })
            .catch((error) => {
                commit(_mutation.SET_PAYOUT_ERROR, helper.processErrorResponse(error));
            });
    }),
    [_action.RESET_PAYOUT_ERROR]({ commit }) {
        commit(_mutation.RESET_PAYOUT_ERROR);
    },
    [_action.RESET_PAYOUT_STATUS]({ commit }) {
        commit(_mutation.RESET_PAYOUT_STATUS);
    },
    [_action.FETCH_PAYOUT_TAX_AMOUNT]: actionLoader(action.FETCH_PAYOUT_TAX_AMOUNT, ({ commit, getters, rootGetters }, amount) => {
        if (!rootGetters[platformGetter.COUNTRY_CODE_IS].GH || getters.isWithdrawWhtDisabled) return Promise.resolve();
        return Vue.$http
            .get(`${endpoints.getPayoutTaxAmount}?amount=${amount}`)
            .then(({ data }) => {
                commit(_mutation.SET_PAYOUT_TAX_AMOUNT, data?.data);
            })
            .catch((error) => {
                commit(_mutation.RESET_PAYOUT_TAX_AMOUNT);
                Vue.$sentry.withScope((scope) => {
                    const { status, statusCode } = error || {};
                    scope.setExtras({ error });
                    scope.setTag('status', status || statusCode);
                    scope.setTag('scope', 'fetchPayoutTaxAmount');
                    scope.setTag('fetchPayoutTaxAmount', 'error');
                    scope.setLevel('fatal');
                    Vue.$sentry.captureMessage('FETCH_PAYOUT_TAX_AMOUNT_ERROR');
                });
                console.error(`${_action.FETCH_PAYOUT_TAX_AMOUNT} Response Error`, [error]);
            });
    }),
    [_action.FETCH_DEPOSIT_TELCO_FEE]: actionLoader(action.FETCH_DEPOSIT_TELCO_FEE, ({ commit, getters }, { amount, depositType }) => {
        if (!getters.isDepositTelcoFeeEnabled) return Promise.resolve();
        return Vue.$http
            .get(`${endpoints.getDepositTelcoFee}?amount=${amount}&depositType=${depositType}`)
            .then(({ data }) => {
                const { amount, compensationAmount } = data;
                const { minDepositAmountForFeeCompensation } = getters.getSelectedProvider;
                let totalAmount = amount >= minDepositAmountForFeeCompensation ? amount + compensationAmount : amount;
                commit(_mutation.SET_DEPOSIT_TELCO_FEE, { ...data, totalAmount });
            })
            .catch((error) => {
                commit(_mutation.RESET_DEPOSIT_TELCO_FEE);
                Vue.$sentry.withScope((scope) => {
                    const { status, statusCode } = error || {};
                    scope.setExtras({ error });
                    scope.setTag('status', status || statusCode);
                    scope.setTag('scope', 'fetchDepositTelcoFee');
                    scope.setTag('fetchDepositTelcoFee', 'error');
                    scope.setLevel('fatal');
                    Vue.$sentry.captureMessage('FETCH_DEPOSIT_TELCO_FEE_ERROR');
                });
                console.error(`${_action.FETCH_DEPOSIT_TELCO_FEE} Response Error`, [error]);
            });
    }),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
