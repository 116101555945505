<template>
    <div class="generic-page">
        <div class="generic-page-content" :class="[pageType, { 'has-back-button': hasBackButton }]">
            <Breadcrumbs v-if="!!page && page.isBreadcrumbsEnabled" :breadcrumbs="page.breadcrumbs" :link="page.link" :label="page.label" />
            <Spinner :listen="spinnerTriggers" class="inset" />
            <ErrorPage
                v-if="contentError && contentError.message && !isNotFoundError"
                icon="icon-reload"
                :title="$t('ui.errorPage.giveItAnotherGo')"
                :sectionList="[$t('ui.errorPage.connectionIssue'), $t('ui.errorPage.tryAgain')]"
                :button="{ text: $t('ui.errorPage.reloadPage'), emit: true }"
                @button:click="errorButtonClick()"
            />
            <template v-else-if="page">
                <div v-if="message" class="page-error">
                    <renderer :input="message" />
                </div>
                <TOC v-if="page.TOC" :content="page.tocContent" />
                <div class="generic-page-content-wrapper" v-if="page.content">
                    <DynamicComponent
                        v-for="(item, index) in page.content"
                        :key="`${slotId || pagePath}-${item.type}-${index}`"
                        :class="[item.cssClass]"
                        :data="item"
                        :path="'content'"
                        :params="params"
                        :data-test-id="`tag-${item.type}`"
                        :data-test-class="`cmsTag`"
                        @action="dispatchAction($event)"
                        @showMessage="message = $event"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { deviceType } from '@agi.packages/core';
import { DynamicComponent } from '@agi.packages/platform/components';
import { action, getter, isSibling } from '@agi.packages/platform';

import TOC from '@/components/TOC.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ErrorPage from '@/components/Pages/ErrorPage';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import { routeName } from '@/router/const-name';

const GENERIC_STYLE_PAGES = ['simple-page', 'error-page-404', 'deposit-option-page', 'referral-page'];

const CMS_NOT_FOUND_ERROR = 17100;

export default {
    name: 'GenericPage',
    mixins: [PageMixin, SEOMixin],
    props: {
        slotId: String,
        params: Object,
        isPreviewRoute: {
            type: Boolean,
            default: false,
        },
    },
    components: { TOC, DynamicComponent, ErrorPage, Breadcrumbs },
    data: function () {
        return {
            spinnerTriggers: [action.GET_PAGE, action.GET_CONTENT],
            pagePath: null,
            pageType: null,
            actions: [],
            message: null,
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapGetters({
            getPages: getter.GET_PAGES,
            getContent: getter.GET_CONTENT_SLOTS,
        }),
        ...mapState({
            contentError: (state) => state.platform.content.error,
        }),
        page() {
            if (this.isPresto && !this.slotId && !this.pagePath) {
                this.applyStyling(this.getPages[this.pagePath]);
            }
            return this.slotId ? this.getContent[this.slotId] : this.getPages[this.pagePath];
        },
        hasBackButton() {
            return this.page?.content?.some((content) => content?.type === 'BackButton');
        },
        isNotFoundError() {
            return this.contentError && (this.contentError.statusCode === 404 || this.contentError.errorCode === CMS_NOT_FOUND_ERROR);
        },
    },
    watch: {
        $route(route) {
            this.$scroll.scrollTo(0, 1);
            this.computePagePath(route);
        },
        contentError() {
            if (this.isNotFoundError) {
                this.$router.push({ name: routeName.NOT_FOUND });
            }
        },
        page(data) {
            this.applyStyling(data);
            const { strapi, path } = data || {};

            if (strapi && !this.isPreviewRoute) {
                this.replacePageUrl(path);
            }
        },
    },
    methods: {
        replacePageUrl(path) {
            if (history && history.replaceState) {
                history.replaceState({}, '', path);
            }
        },
        getByPathOrSlotId(route, isSibling) {
            if (this.slotId) {
                isSibling && this.$store.dispatch(action.GET_CONTENT, this.slotId);

                return;
            }

            if (this.isPreviewRoute) {
                this.pagePath = routeName.PREVIEW;
                this.$store.dispatch(action.GET_PAGE_PREVIEW, {
                    path: this.pagePath,
                    previewId: route.params.id,
                });

                return;
            }

            this.computePagePath(route);

            if (!isSibling) {
                this.$store.dispatch(action.GET_PAGE, this.pagePath);
            }
        },
        computePagePath(route) {
            const pathMatch = route.params.pathMatch || route.path;
            const isHomePage = pathMatch === '/';

            this.pagePath = isHomePage
                ? '/'
                : pathMatch
                      .split('/')
                      .filter((segment) => !!segment)
                      .pop();
        },
        applyStyling(data) {
            const { PageTemplate } = data || {};
            const pageType = PageTemplate && PageTemplate.replace(/\s/g, '-').toLowerCase();
            const pageId = this.slotId && this.slotId.replace(/_/g, '-').toLowerCase();
            const genericClass = (GENERIC_STYLE_PAGES.includes(pageType) || GENERIC_STYLE_PAGES.includes(pageId)) && 'simple-page';
            const allowGenericStyling = !this.$route.meta?.useCustomStyle;
            this.pageType = allowGenericStyling && genericClass;
        },
        dispatchAction(payload) {
            const { action, params, useSportParams } = payload;
            if (!this.actions.includes(action)) {
                this.actions.push(action);
                this.spinnerTriggers.push(action);
                const actionPayload = {
                    ...params,
                    ...(useSportParams && { sportParams: this.page.sportParams }),
                };
                this.$store.dispatch(action, actionPayload);
            }
        },
        resetTempData() {
            this.actions = [];
            this.message = null;
        },
        errorButtonClick() {
            window.location.reload();
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.resetTempData();
            vm.getByPathOrSlotId(to, isSibling(to.matched, from.path));
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.resetTempData();
        this.getByPathOrSlotId(to, isSibling(to.matched, from.path));
        next();
    },
};
</script>

<style scoped lang="scss">
.generic-page-content.simple-page {
    margin: 20px;

    p {
        @extend %body-big-font-400;
    }

    h1 {
        @extend %h1-font-700;
    }

    .no-margins {
        width: auto;
        margin-left: -20px;
        margin-right: -20px;

        &:first-child,
        &:last-child {
            margin-top: -20px;
        }

        &.single-event {
            &:first-child {
                margin-top: -20px;
            }
        }
    }
}
.generic-page-content.has-back-button {
    margin: 0;
    padding: 20px;
}
</style>
