<template>
    <component
        v-if="component"
        :is="component"
        :data="data"
        :id="data.id"
        :params="params"
        :isDarkBackground="isDarkBackground"
        @action="$emit('action', $event)"
        @showMessage="$emit('showMessage', $event)"
    />
</template>
<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { mapState, mapGetters } from 'vuex';

import { config, helper, deviceType, DEVICE_TYPE_NAMES } from '@agi.packages/core'; // move userState to platform BP-16141
import { getter as platformGetter, mutation } from '@agi.packages/platform';
const sportMapping = {
    EVENTS: 'EventsView',
    EVENT: 'SingleEventView',
};
// remove temp content list BP-16141
const sportContentTypes = ['SingleEventView', 'EventsView'];
const platformMapping = {
    CASINOGAMES: 'CasinoGames',
    DEPOSIT_OPTIONS: 'Depositoptions',
    HOSTNAME: 'Text',
    HOSTNAMEPROTOCOL: 'Text',
    HOW_TO_DEPOSIT: 'Howtodeposit',
    HR: 'Horizontalrule',
    IMAGE: 'LinkImage',
    LINKEXTERNAL: 'Link',
    LINKIMAGEEXTERNAL: 'LinkImage',
    LINKIMAGEINTERNAL: 'LinkImage',
    LOGIN: 'Login',
    MTN_AIRTIME_BANNER: 'Mtnairtimebanner',
    NESTED_COMPONENTS: 'NestedComponents',
    PACKAGE_IMAGE: 'PackageImage',
    PAYMENTCOMPONENT: 'Paymentcomponent',
    QUICKLINKS: 'QuickLinksWrapper',
    REFERFACEBOOK: 'Social',
    REFERRALFREEBETLINK: 'Referral',
    REFERRALSIGNUPLINK: 'Referral',
    REFERTWITTER: 'Social',
    REFERWHATSAPP: 'Social',
    REFER_A_FRIEND: 'Referafriend',
    SHARELINKFACEBOOK: 'Social',
    SHARELINKTWITTER: 'Social',
    SHARELINKWHATSAPP: 'Social',
    SIGNUP: 'Signup',
    WIN_BONUS: 'Winbonus',
    // STRAPI TEMP MAPPINGS
    BackButton: 'BackButton',
    CallMe: 'Callmecomponent',
    Doc: 'Doc',
    PagesPreview: 'PagesPreview',
    QuickLinks: 'QuickLinks',
    LeagueShortcut: 'LeagueShortcut',
    RichText: 'RichText',
    Survey: 'Survey',
    Toc: 'TocComponent',
    VerifyButton: 'VerifyButton',
    Video: 'Video',
    VideoStream: 'VideoStream',
    anchor: 'Anchor',
    blockquote: 'Blockquote',
    bulletList: 'BulletList',
    button: 'Button',
    heading: 'Heading',
    horizontalRule: 'Horizontalrule',
    image: 'Image',
    orderedList: 'OrderedList',
    paragraph: 'Paragraph',
    table: 'Table',
    ProgressiveJpBanner: 'ProgressiveJpBanner',
    ProgressiveJpWinners: 'ProgressiveJpWinners',
    // STRAPI TEMP MAPPINGS END
};
// remove temp content list BP-16141
const platformContentTypes = [
    'Bold',
    'Brandname',
    'Hashtag',
    'Headline',
    'Italic',
    'Link',
    'Linkhashtag',
    'LinkImage',
    'Linkinternal',
    'List',
    'Mainheadline',
    'Referral',
    'Sendername',
    'Social',
    'Subheadline',
    'Text',
    'Unordereditem',
    'Userbalance',
    'Userfullname',
    'Userphonenumber',
    'Signup',
    'Login',
];
const PRELOAD_COMPONENTS = {};

if (deviceType.isPresto()) {
    [platformMapping.NESTED_COMPONENTS].forEach((value) => {
        PRELOAD_COMPONENTS[`content/${value}`] = import(`@/components/content/${value}`).catch(() =>
            this.debugComponent(`failed to preload component with path ${value}`)
        );
    });
}

const DISABLED_TYPES = ['Ordereditem', 'Unordereditem'];

export default {
    name: 'DynamicComponent',
    props: {
        data: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: '',
        },
        isDarkBackground: {
            type: Boolean,
            default: false,
        },
        path: {
            type: String,
            default: '',
        },
        params: Object,
    },
    computed: {
        ...mapState({
            userSettings: (state) => state.platform.settings.user,
        }),
        ...mapGetters({
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
        }),
        component() {
            try {
                if (this.isDisabled || !this.isVisible) {
                    return null;
                }
                if (!this.getType) {
                    if (!config.isProd()) {
                        return () => import(`@agi.packages/core/components`).then((module) => module['LoadingStub']);
                    } else {
                        this.debugComponent('not found');
                    }
                }
                const path = `${this.getPath}${this.getType}`;

                if (PRELOAD_COMPONENTS[path]) {
                    return () => PRELOAD_COMPONENTS[path];
                } else if (Vue.options.components[this.getType]) {
                    return Vue.options.components[this.getType];
                } else {
                    return () => this.getModuleOrDefault(this.getType, path);
                }
            } catch (error) {
                Sentry.setExtras({
                    error,
                    props: this.$props,
                });
                Sentry.captureException(new Error(`[Dynamic Component] Error`));
                return null;
            }
        },
        getType() {
            const type = this.type || this.data.type;
            if (!type) {
                console.error(`[DynamicComponent]: Data type is ${type}`, this.$props);
                return null;
            }
            return platformMapping[type] || sportMapping[type] || helper.capitalize(type);
        },
        getPath() {
            return this.path ? `${this.path}/` : this.path;
        },
        isDisabled() {
            return DISABLED_TYPES.includes(this.getType);
        },
        isVisible() {
            if (!this.isVisibleForPlatform) {
                return false;
            }

            const visibility = this.data.visibility?.userState || this.data.visibility;
            return helper.hasValidVisibility(visibility, this.currentUserStatus);
        },
        isVisibleForPlatform() {
            switch (this.data.targetPlatform) {
                case DEVICE_TYPE_NAMES.APP:
                    return deviceType.isApp();
                case DEVICE_TYPE_NAMES.WEB:
                    return !deviceType.isApp();
                default:
                    return true;
            }
        },
        isComponentAdded() {
            return this.getType && !this.isDisabled && this.isVisible;
        },
    },
    updated() {
        this.$nextTick(() => {
            const { hash } = this.$router.currentRoute;
            try {
                // TODO remove when casino fixes incorrect parameters
                if (!hash || !document.querySelector(hash)) {
                    return;
                }
                this.$root.$emit('triggerScroll');
            } catch (ex) {
                // silent
            }
        });
    },
    methods: {
        getModuleOrDefault(name, path) {
            if (platformContentTypes.includes(name)) {
                return import(`@agi.packages/platform/components`)
                    .then((module) => module[name])
                    .catch(() => this.debugComponent(`Platform module load failed`));
            }
            if (sportContentTypes.includes(name)) {
                return import(`@agi.packages/sport/components`)
                    .then((module) => module[name])
                    .catch(() => this.debugComponent(`Sport module load failed`));
            }
            return import(`@/components/${path}`).catch(() => this.debugComponent(`module load failed with path ${path}`));
        },
        debugComponent(context) {
            throw new Error(`[DynamicComponent] Type ${this.data.type}, ${context}`);
        },
    },
    watch: {
        component: {
            immediate: true,
            handler(value) {
                if (platformMapping.PAYMENTCOMPONENT === this.getType) {
                    this.$store.commit(mutation.SET_IS_PAYMENT_COMPONENT_VISIBILITY_RULE_PASSED, !!value);
                }
            },
        },
    },
};
</script>
