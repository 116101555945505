var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lmenu-main"},[_c('LeftMenuCategories',[_c('span',{staticClass:"close",on:{"click":_vm.hideLeftMenu}},[_c('SvgIcon',{attrs:{"icon-id":"icon-close"}})],1)]),_vm._l((_vm.leftMenu),function(menuItem,index){return _c('MenuItem',{key:menuItem.pageId + '-left-menu-' + index,attrs:{"selected":_vm.hasStrapiMenu ? _vm.isCurrentMenuItem(menuItem) && !menuItem.disableHighlight : _vm.currentRoute.name === menuItem.pageId,"link":{
            path: menuItem.url,
            query: menuItem.query,
        },"item":{
            badge: _vm.hasStrapiMenu ? menuItem.badge : _vm.$t(menuItem.badge),
            badgeType: _vm.hasStrapiMenu ? menuItem.badgeType : 'new-messages',
            badgeColor: _vm.hasStrapiMenu ? menuItem.badgeColor : '',
            icon: _vm.hasStrapiMenu ? menuItem.icon : _vm.menuIcons[menuItem.pageId.toLowerCase()],
            text: _vm.hasStrapiMenu ? menuItem.name : _vm.$t(menuItem.key, { casinoName: _vm.casinoName }),
            control: {
                text: menuItem.pageId === 'Live' && _vm.liveEventsCount,
            },
        }},nativeOn:{"click":function($event){_vm.currentRoute.name === menuItem.pageId ? _vm.hideLeftMenu() : _vm.trackMainNav(menuItem.pageId)}}})}),_c('TournamentsList',{attrs:{"title":_vm.$t('ui.lMenu.tournaments')},on:{"onMenuItemClick":function($event){return _vm.trackMainNav($event.element, $event.event)}}}),_c('CountryMenus')],2)}
var staticRenderFns = []

export { render, staticRenderFns }