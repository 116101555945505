<template>
    <div v-if="isAuthenticated" class="withdraw-component">
        <div class="withdraw-form-wrapper">
            <template v-if="isProvidersLoading">
                <Spinner visible class="inset" />
            </template>
            <div class="withdraw-form" v-else-if="Array.isArray(providers) && providers.length">
                <div class="notify warning notify-status" v-if="provider.status && provider.status !== paymentMethodStatus.OPERATIONAL">
                    {{ $t('ui.payment.payout.notification.notOperational') }}
                </div>
                <UserPhoneOperator v-if="!status.success" :providerIcon="providerIcon" class="withdraw-phone" />
                <Spinner :visible="fetchingRegions" class="align-center inset viewport-padding" />
                <form class="form" v-if="!fetchingRegions" :disabled="inProgress" @submit.prevent="withdraw">
                    <template v-if="!status.success">
                        <div v-if="showGhanaKYC && !$v.form.ghanaKYC.$model" class="notify warning" :style="{ margin: `0 0 16px` }">
                            {{ $t('ui.payment.payout.ghana.pickIdTypeHint') }}
                        </div>
                        <InputField
                            class="withdraw-amount"
                            name="amount"
                            :type="amountInputAttr.type"
                            canChange
                            :formName="formNameId"
                            :value="form.amount"
                            :attrs="{
                                pattern: amountInputAttr.pattern,
                                inputmode: amountInputAttr.inputmode,
                            }"
                            @value="setAmount"
                            :v="$v.form.amount"
                            :helpText="amountHelpText"
                            :errorMessages="errorMessages.amount"
                            :currencySymbol="currencySymbol"
                            :disabled="isAmountFieldDisabled"
                            :step="amountInputAttr.step"
                        >
                            <template v-slot:optional>
                                <LabelWithContent :labelText="withdrawFeeLabelText" class="withdraw-fee">
                                    <span v-if="isFreeWithdrawalAvailable" @click.stop.prevent="$modal.show('withdraw-fee-info-modal')">
                                        <SvgIcon
                                            class="withdraw-fee-info-icon icon-size-medium"
                                            icon-id="icon-info"
                                            verticalAlign="text-top"
                                        />
                                        <Modal
                                            width="280px"
                                            class="withdraw-fee-info-modal"
                                            name="withdraw-fee-info-modal"
                                            @close="$modal.hide('withdraw-fee-info-modal')"
                                        >
                                            <div class="withdraw-fee-info-text">
                                                {{ withdrawalFeeModalText }}
                                            </div>
                                        </Modal>
                                    </span>
                                </LabelWithContent>
                            </template>
                            <template v-slot:label>{{ $t('ui.payment.payout.amount') }}</template>
                        </InputField>
                        <template v-if="isProviderSelectionVisible">
                            <div v-if="!fetchingRegions && providerDescription && providerDescription.length" class="text">
                                <DynamicComponent
                                    v-for="(item, index) in providerDescription"
                                    :key="'withdraw-form-text' + index"
                                    :data="item"
                                    path="content"
                                />
                            </div>
                            <label :for="`${formNameId}-provider`" class="form">
                                {{ $t('ui.payment.payout.providerLabel') }}
                            </label>
                            <select
                                type="text"
                                name="provider"
                                :id="`${formNameId}-provider`"
                                v-model="form.provider"
                                @change="$v.form.provider.$touch()"
                                class="form global-select"
                                :class="[$v && $v.form.provider.$dirty && ($v.form.provider.$error ? 'error' : 'valid')]"
                                :disabled="isProviderSelectDisabled"
                                :errorMessages="errorMessages.provider"
                            >
                                <option :value="{}">
                                    {{ $t('ui.payment.payout.selectWithdrawalOption') }}
                                </option>
                                <option
                                    v-for="({ payoutType, name }, index) in providers"
                                    :key="`${payoutType}|${index}`"
                                    :value="{ payoutType, index }"
                                >
                                    {{ name }}
                                </option>
                            </select>
                        </template>
                        <InputField
                            v-if="isExperimental"
                            name="password"
                            type="password"
                            :formName="formNameId"
                            :value="form.password"
                            @value="form.password = $event"
                            :v="$v.form.password"
                            :errorMessages="errorMessages.password"
                        >
                            <template v-slot:label>Password</template>
                        </InputField>
                        <template v-if="isRequiredField(fieldType.BANK) && provider.banks && provider.payoutType">
                            <label :for="`${formNameId}-bank`" class="form">{{ $t('ui.payment.payout.bank') }}</label>
                            <select
                                type="text"
                                name="bank"
                                :value="form.bank"
                                @change="setBank($event.target.value)"
                                :id="`${formNameId}-bank`"
                                :class="[$v && $v.form.bank.$dirty && ($v.form.bank.$invalid ? 'error' : 'valid')]"
                                class="form global-select"
                            >
                                <option value="">{{ $t('ui.payment.payout.selectYourBank') }}</option>
                                <option v-for="(bank, index) of provider.banks" :key="`${bank.Id}-bank-${index}`" :value="bank.Id">
                                    {{ bank.Name }} ({{ bank.Code }})
                                </option>
                            </select>
                        </template>
                        <template v-if="isRequiredField(fieldType.ACCOUNT_NUMBER) && provider.banks && provider.payoutType">
                            <InputField
                                name="account-number"
                                :type="isPresto ? 'text' : 'number'"
                                class="account-number"
                                stringify
                                :attrs="{ pattern: '[0-9]*', maxLength: 10 }"
                                :formName="formNameId"
                                :value="form.accountNumber"
                                :v="$v.form.accountNumber"
                                :errorMessages="errorMessages.required"
                                @value="form.accountNumber = $event"
                                :helpText="$t(`ui.countries.${platformCountry}.form.help.accountNumber`, { indefinite: true })"
                            >
                                <template slot="label">{{ $t('ui.payment.payout.accountNumber') }}</template>
                            </InputField>
                        </template>
                        <template v-if="!$isEmpty(provider) && !provider.firstName && isRequiredField(fieldType.FIRST_NAME)">
                            <InputField
                                name="firstName"
                                type="text"
                                :formName="formNameId"
                                :value="form.firstName"
                                @value="form.firstName = $event"
                                :v="$v.form.firstName"
                                :errorMessages="errorMessages.firstName"
                            >
                                <template slot="label">{{ $t('ui.common.user.firstName') }}</template>
                            </InputField>
                        </template>
                        <template v-if="!$isEmpty(provider) && !provider.lastName && isRequiredField(fieldType.LAST_NAME)">
                            <InputField
                                name="lastName"
                                type="text"
                                :formName="formNameId"
                                :value="form.lastName"
                                @value="form.lastName = $event"
                                :v="$v.form.lastName"
                                :errorMessages="errorMessages.lastName"
                            >
                                <template slot="label">
                                    {{ $t('ui.common.user.lastName') }}
                                </template>
                            </InputField>
                        </template>
                        <template v-if="!provider.region && regions.length && isRequiredField(fieldType.LOCATION)">
                            <label :for="`${formNameId}-location`" class="form">
                                {{ $t('ui.common.user.location') }}
                            </label>
                            <select
                                type="text"
                                name="location"
                                :id="`${formNameId}-location`"
                                v-model="form.location"
                                class="form global-select"
                                @change="$v.form.location.$touch()"
                                :class="[$v && $v.form.location.$dirty && ($v.form.location.$error ? 'error' : 'valid')]"
                            >
                                <option value="">{{ $t('ui.common.select') }} {{ $t(`project.ui.region.${platformCountry}`) }}</option>
                                <option v-for="region of regions" :key="region.id" :value="region.id">{{ region.name }}</option>
                            </select>
                        </template>
                        <template v-if="showGhanaKYC">
                            <label :for="`${formNameId}-ghanaKYC`" class="form">
                                {{ $t('ui.payment.payout.ghana.idType') }}
                            </label>
                            <select
                                type="text"
                                name="ghanaKYC"
                                :id="`${formNameId}-ghanaKYC`"
                                v-model="form.ghanaKYC"
                                @change="$v.form.ghanaKYC.$touch()"
                                class="form warn global-select"
                                :class="[$v && $v.form.ghanaKYC.$dirty && ($v.form.ghanaKYC.$error ? 'error' : 'valid')]"
                                :errorMessages="errorMessages.ghanaId"
                            >
                                <option value="null" hidden>
                                    {{ $t('ui.payment.payout.ghana.selectType') }}
                                </option>
                                <option v-for="({ id, text }, index) in ghanaFields" :key="`${id}-${index}`" :value="id">
                                    {{ text }}
                                </option>
                            </select>
                            <InputField
                                name="ghanaId"
                                type="text"
                                :formName="formNameId"
                                :value="form.ghanaId"
                                @value="form.ghanaId = $event"
                                :v="$v.form.ghanaId"
                                :errorMessages="errorMessages.ghanaId"
                            >
                                <template slot="label">{{
                                    form.ghanaKYC
                                        ? ghanaFields.find(({ id }) => id === form.ghanaKYC).text + ' Number'
                                        : $t('ui.payment.payout.ghana.idNumber')
                                }}</template>
                            </InputField>
                        </template>
                        <renderer
                            v-if="!inProgress && error"
                            :input="error"
                            :options="{ balance: $options.filters.currency(balance, currencyFormat.format) }"
                            class="notify error"
                        />
                        <renderer v-else-if="isWarningMessage && !status.error" class="notify warning" :input="isWarningMessage" />
                    </template>
                    <template v-if="isTaxEnabled && isFormAmountValid">
                        <template v-if="isWhtVisible">
                            <div class="wht-summary text">
                                <LabelWithContent
                                    v-for="(summary, whtIdx) in withdrawalSummaryList"
                                    :key="`${whtIdx}-summary-info`"
                                    :labelText="summary.infoLabel"
                                    class="summary-wrapper"
                                >
                                    <template v-slot:icon>
                                        <span v-if="summary.modalContent" @click="$modal.show(`${whtIdx}-summary-info`)" class="info">
                                            <SvgIcon class="icon-size-medium" icon-id="icon-info" verticalAlign="text-top" />
                                        </span>
                                    </template>
                                    <Currency
                                        :class="`${summary.classes}`"
                                        :amount="summary.amount"
                                        :isLoading="summary.isLoading"
                                        :contrast="false"
                                        :dataTestId="summary.dataTestId"
                                    />
                                    <Modal
                                        v-if="summary.modalContent"
                                        class="wht-info-modal"
                                        :name="`${whtIdx}-summary-info`"
                                        width="300px"
                                        avoidCollisionWithFooter
                                        @close="$modal.hide(`${whtIdx}-summary-info`)"
                                    >
                                        {{ summary.modalContent }}
                                    </Modal>
                                </LabelWithContent>
                            </div>
                        </template>
                        <template v-else>
                            <div class="wht-summary-notify notify warning">
                                <renderer :input="$t('ui.payment.payout.error.taxCalculation')" />
                            </div>
                        </template>
                    </template>
                    <template v-else-if="isWithdrawalFeeDetailsVisible">
                        <div class="wht-summary text">
                            <LabelWithContent
                                v-for="(summary, smIdx) in freeWithdrawalSummaryList"
                                :key="`${smIdx}-free-wth-info`"
                                :labelText="summary.infoLabel"
                                class="summary-wrapper"
                            >
                                <Currency
                                    v-if="$isNumber(summary.amount)"
                                    :class="`${summary.classes}`"
                                    :amount="summary.amount"
                                    :contrast="false"
                                />
                            </LabelWithContent>
                        </div>
                        <renderer v-if="isWithdrawalFeeError" class="notify error" :input="withdrawalFeeError" />
                    </template>
                    <div v-if="!statusMessageLoading">
                        <div
                            v-if="status.cmsId && statusMessage && statusMessage.length"
                            :class="['notify', status.success ? 'success' : 'error']"
                        >
                            <DynamicComponent
                                v-for="(item, index) in statusMessage"
                                :key="'withdraw-form-message' + index"
                                :data="item"
                                path="content"
                            />
                        </div>
                        <div v-else>
                            <div class="notify error" v-if="status.error">
                                <renderer :input="status.error" />
                            </div>
                            <div v-if="status.success" class="notify success">
                                {{
                                    $t('ui.payment.payout.success', {
                                        amount: $options.filters.currency(form.amount, currencyFormat.format),
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                    <button
                        v-if="!status.success"
                        class="button button-primary button-full"
                        :disabled="inProgress || isTaxLoading || (!isPresto && $v.$invalid) || !form.amount"
                        type="submit"
                    >
                        {{
                            !isFreeWithdrawalAvailable
                                ? $t('ui.payment.payout.requestWithdraw')
                                : $t('ui.payment.payout.requestFreeWithdraw')
                        }}
                    </button>
                </form>
            </div>
            <template v-else>
                <renderer class="notify warning" :input="$t('ui.payment.payout.notSupported')" />
            </template>
        </div>

        <div v-if="pending.length" class="pending-requests">
            <h2>{{ $t('ui.payment.payout.pendingWithdraw') }}</h2>
            <div class="pending-payout" v-for="(payout, index) of pending" :key="index">
                <div class="details">
                    <div class="date">
                        <b>{{ $t('ui.payment.payout.date') }}: </b>
                        <span>{{ formatDateTime(payout.date) }}</span>
                    </div>
                    <Currency class="bold" :amount="Number(payout.amountUnformatted)" :format="currencyFormat">
                        {{ $t('ui.payment.payout.amount') }}:
                    </Currency>
                </div>
                <div class="cancel">
                    <button
                        class="button button-accent"
                        :disabled="inProgress"
                        data-test-class="cancelPayoutButton"
                        @click="cancelPayout(payout.id)"
                    >
                        {{ $t('ui.payment.payout.cancelWithdraw') }}
                    </button>
                </div>
            </div>
        </div>
        <template v-if="payoutError">
            <div class="notify error">
                <renderer :input="payoutError" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { required, between, integer } from 'vuelidate/lib/validators';
import { helper, VALIDATORS, deviceType, getter as coreGetter, attributesForNumberInput } from '@agi.packages/core';
import { InputField, Spinner, Currency } from '@agi.packages/core/components';
import { action, mutation, getter, paymentMethodStatus, telcoIcon } from '@agi.packages/payment';
import { auth, action as platformAction, getter as platformGetter, mutation as platformMutation } from '@agi.packages/platform';
import { getter as translationsGetter } from '@/store/modules/translations';
import { UserPhoneOperator } from '@agi.packages/payment/components';
import { freeWithdrawalPeriod } from '@/modules/payment/withdrawal-const';

import LabelWithContent from '@/modules/payment/components/LabelWithContent.vue';
import { isNumber } from '@/modules/core/utils/number/isNumber';
import { getProviderOptionByProviderName } from '@/modules/platform/utils/getProviderOptionByProviderName';

const DEFAULT_FIELDS = ['amount', 'channel', 'payoutType', 'payoutTypeName', 'isNameSet'];

const fieldType = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    LOCATION: 'location',
    BANK: 'BankId',
    ACCOUNT_NUMBER: 'BankAccountNumber',
};

const CANNOT_WITHDRAW_HAVE_NO_MONEY_ERROR = 18454;
const DEFAULT_TAX_DEBOUNCE_TIMOUT = 1000;

export default {
    name: 'Withdraw',
    components: { InputField, Spinner, UserPhoneOperator, Currency, LabelWithContent },
    data: function () {
        return {
            paymentMethodStatus,
            formNameId: 'withdraw-form',
            stopFormProviderWatch: null,
            form: {
                accountNumber: '',
                amount: null,
                firstName: '',
                lastName: '',
                location: '',
                bank: '',
                password: '',
                ghanaKYC: null,
                ghanaId: '',
                provider: {},
            },
            isPresto: deviceType.isPresto(),
            isIos: deviceType.isIos(),
            fieldType,
            showGhanaKYC: false,
            ghanaFields: [
                { text: 'Voter Card', id: 'VOTER' },
                { text: 'Old Voter Card', id: 'OLD_VOTER' },
                { text: "Driver's License", id: 'DRIVER_LICENCE' },
                { text: 'Passport', id: 'PASSPORT' },
                { text: 'SSNIT', id: 'SSNIT' },
            ],
            isFetchTaxDebouncing: false,
            isWithdrawalFeeError: false,
        };
    },
    validations() {
        const validators = {
            form: {
                ...(this.providers?.length > 0 && { provider: { required } }),
            },
        };
        if (!this.$isEmpty(this.provider)) {
            validators.form.amount = {
                amount: this.amountValidator,
                between: between(this.provider.minAmount, this.provider.maxAmount),
            };
            if (!this.provider.fractionalSupported) {
                validators.form.amount = {
                    ...validators.form.amount,
                    integer,
                };
            }
        }
        if (this.isRequiredField(fieldType.FIRST_NAME) && !this.provider.firstName) {
            validators.form.firstName = { required };
        }
        if (this.isRequiredField(fieldType.LAST_NAME) && !this.provider.lastName) {
            validators.form.lastName = { required };
        }
        if (this.isExperimental) {
            validators.form.password = { required };
        }
        if (this.isRequiredField(fieldType.ACCOUNT_NUMBER) && this.provider.banks) {
            validators.form.accountNumber = { required };
        }
        if (this.isRequiredField(fieldType.BANK) && this.provider.banks) {
            validators.form.bank = { required };
        }
        if (this.isRequiredField(fieldType.LOCATION) && !this.provider.region && this.regions.length) {
            validators.form.location = { required };
        }
        if (this.showGhanaKYC && this.form.ghanaKYC) {
            validators.form.ghanaId = {
                ghanaId: VALIDATORS['GH_' + this.form.ghanaKYC],
                required,
            };
            validators.form.ghanaKYC = { ghanaKYC: this.selectValidator };
        } else {
            validators.form.ghanaKYC = { ghanaKYC: this.selectValidator };
        }

        return validators;
    },
    created() {
        if (this.isAuthenticated) {
            if (this.isTaxEnabled) {
                const { withdrawalTaxDebounceTimeout } = this.brandPreference;
                this.debouncedFetchTaxCallback = helper.debounce(
                    (amount) => this.$store.dispatch(action.FETCH_PAYOUT_TAX_AMOUNT, amount),
                    Number(withdrawalTaxDebounceTimeout) || DEFAULT_TAX_DEBOUNCE_TIMOUT,
                    (isDebouncing) => {
                        this.isFetchTaxDebouncing = isDebouncing;
                    }
                );
            }
            this.$store.dispatch(action.RESET_PAYOUT_ERROR);
            this.$store.dispatch(action.FETCH_PAYOUT_PROVIDERS);
        }
    },
    mounted() {
        if (this.isAuthenticated) {
            this.$store.dispatch(mutation.RESET_PAYOUT_STATUS);
            if (!this.provider.region && !this.countryCodeIs.GH) {
                this.$store.dispatch(platformAction.FETCH_REGIONS);
            }
            if (this.provider.cmsId) {
                this.$store.dispatch(platformAction.GET_TEXT, [this.provider.cmsId]);
            }
        }
    },
    computed: {
        ...mapGetters({
            areLoading: coreGetter.ARE_LOADING,
            status: getter.GET_PAYOUT_STATUS,
            text: platformAction.GET_TEXT,
            isLoading: coreGetter.IS_LOADING,
            currentLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
            preference: platformGetter.GET_PREFERENCE,
            balance: auth.getter.GET_BALANCE,
            providers: getter.GET_PAYOUT_PROVIDERS,
            payoutError: getter.GET_PAYOUT_ERROR,
            pending: getter.GET_PENDING_PAYOUTS,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            platformCountry: platformGetter.GET_COUNTRY,
            isWithdrawWhtDisabled: getter.IS_WITHDRAW_WHT_DISABLED,
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            depositOptions: platformGetter.GET_DEPOSIT_OPTIONS_CONFIG,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        ...mapState({
            currencySymbol: (state) => state.platform.settings.currency.symbol,
            isExperimental: (state) => state.payment.payout.isExperimental,
            paymentSettings: (state) => state.platform.settings.payment,
            currencyFormat: (state) => state.platform.settings.currency,
            regions: (state) => state.platform.regions,
            error: (state) => state.platform.auth.error,
            whtTax: (state) => state.payment.payout.taxAmount,
        }),
        jurisdictionId() {
            return this.brandPreference.jurisdictionId;
        },
        isWarningMessage() {
            return this.$t(`ui.payment.payout.region.${this.jurisdictionId}.warningMessage`, { indefinite: true });
        },
        inProgress() {
            return this.areLoading([action.WITHDRAW, action.CANCEL_PAYOUT]);
        },
        provider() {
            const { index } = this.form.provider;
            return this.providers ? this.providers[index] || {} : {};
        },
        providerIcon() {
            const providerOption = getProviderOptionByProviderName(this.provider.paymentProvider, this.depositOptions);
            const iconFromStrapi = helper.getObjectField(providerOption, 'attributes.icon.data.attributes.url');
            const icon = telcoIcon[this.provider.paymentProvider] || telcoIcon.DEFAULT;
            return iconFromStrapi || `img/providers/${icon}.png`;
        },
        isFreeWithdrawalEnabled() {
            const { freeWithdrawalsCountPerWeek, freeWithdrawalsCount24h } = this.provider || {};
            return isNumber(freeWithdrawalsCount24h) || isNumber(freeWithdrawalsCountPerWeek);
        },
        freeWithdrawalsCount() {
            const { freeWithdrawalsCount24h, freeWithdrawalsCountPerWeek } = this.provider || {};
            return freeWithdrawalsCountPerWeek || freeWithdrawalsCount24h;
        },
        isFreeWithdrawalAvailable() {
            const { successfulPayoutsToday, thisWeeksSuccessfulPayoutsCount, freeWithdrawalsCount24h, freeWithdrawalsCountPerWeek } =
                this.provider || {};
            return (
                this.isFreeWithdrawalEnabled &&
                (freeWithdrawalsCount24h - successfulPayoutsToday > 0 || freeWithdrawalsCountPerWeek - thisWeeksSuccessfulPayoutsCount > 0)
            );
        },
        feeFormat() {
            const { percentageFee, flatFee } = this.provider || {};
            return percentageFee ? `${percentageFee}%` : `${this.currencySymbol} ${flatFee}`;
        },
        withdrawalUsed() {
            const { successfulPayoutsToday, thisWeeksSuccessfulPayoutsCount, freeWithdrawalsCount24h, freeWithdrawalsCountPerWeek } =
                this.provider || {};

            if (freeWithdrawalsCountPerWeek) {
                return thisWeeksSuccessfulPayoutsCount;
            }

            return freeWithdrawalsCount24h ? successfulPayoutsToday : '';
        },
        isWithdrawalFeeDetailsVisible() {
            return this.fee && this.isFormAmountValid && !this.isFreeWithdrawalAvailable;
        },
        fee() {
            const { percentageFee, flatFee } = this.provider || {};
            if (percentageFee || flatFee) {
                const feeAmount = percentageFee ? this.form.amount * (percentageFee / 100) : flatFee;
                const netAmount = this.form.amount - feeAmount;
                return {
                    amount: this.form.amount,
                    feeAmount,
                    netAmount: netAmount > 0 ? netAmount : 0,
                };
            }
            return null;
        },
        withdrawFeeLabelText() {
            if (!this.isFreeWithdrawalEnabled) {
                return this.$t('ui.payment.payout.free');
            }

            return this.isFreeWithdrawalAvailable
                ? this.$t('ui.payment.payout.freeWithdrawalUsed', {
                      withdrawalUsed: this.withdrawalUsed,
                      freeWithdrawalsCount: this.freeWithdrawalsCount,
                  })
                : this.$t('ui.payment.payout.withdrawalFeeInfo', { fee: this.feeFormat });
        },
        withdrawalFeeModalText() {
            const { freeWithdrawalsCountPerWeek, freeWithdrawalsCount24h } = this.provider || {};
            const freeWithdrawalFreq =
                (freeWithdrawalsCountPerWeek && freeWithdrawalPeriod.WEEKLY) ||
                (freeWithdrawalsCount24h && freeWithdrawalPeriod.DAILY) ||
                '';

            return this.$t(`ui.payment.payout.withdrawalFeeModalInfo.${freeWithdrawalFreq}`, {
                freeWithdrawalsCount: this.freeWithdrawalsCount,
                fee: this.feeFormat,
            });
        },
        isProviderSelectionVisible() {
            if (Object.keys(this.provider).length && this.providers?.length === 1) return false;
            return this.providers?.length > 0;
        },
        errorMessages() {
            const required = this.$t('ui.common.form.error.required');
            const between = this.$t('ui.payment.payout.error.amountMinMax', {
                min: this.$numberFormat(this.provider.minAmount, null, null),
                max: this.$numberFormat(this.provider.maxAmount, null, null),
            });
            const amount = this.isCardless ? this.$t('ui.payment.payout.error.cardlessWithdrawl') : between;

            const ghanaId = this.form.ghanaKYC
                ? this.$t(`ui.payment.payout.ghana.${'GHKYC_' + this.form.ghanaKYC}`, { indefinite: true })
                : '';

            const rounded = Math.floor(this.form.amount);
            const integer = this.$t('ui.common.form.error.integer', { down: rounded, up: rounded + 1 });

            return {
                firstName: { required },
                lastName: { required },
                amount: { amount, between, integer },
                password: { required },
                ...(ghanaId && { ghanaId: { ghanaId } }),
            };
        },
        providerDescription() {
            const cmsId = this.provider.cmsId;
            return this.text[cmsId] && this.text[cmsId].content;
        },
        statusMessage() {
            const message = this.text[this.status.cmsId];
            return message && message.languages ? message.languages[this.currentLanguage] : message.content;
        },
        statusMessageLoading() {
            return this.isLoading(platformAction.GET_TEXT);
        },
        fetchingRegions() {
            return this.isLoading(platformAction.FETCH_REGIONS);
        },
        isTaxLoading() {
            return this.isFetchTaxDebouncing || this.isLoading(action.FETCH_PAYOUT_TAX_AMOUNT);
        },
        isCardless() {
            return this.provider.name === 'Cardless Withdrawal';
        },
        requiredFields() {
            return (this.paymentSettings.REQUIRED_FIELDS || []).filter((key) => this.fieldType[key]).map((key) => this.fieldType[key]);
        },
        amountHelpText() {
            if (this.$isEmpty(this.provider)) {
                return '';
            }
            return this.$t('ui.payment.payout.minMaxAmountHint', {
                min: this.$numberFormat(this.provider.minAmount, null, null),
                max: this.$numberFormat(this.provider.maxAmount, null, null),
            });
        },
        isProviderSelectDisabled() {
            return this.providers.length < 2 && !this.$isEmpty(this.provider);
        },
        isTaxEnabled() {
            return this.countryCodeIs.GH && !this.isWithdrawWhtDisabled;
        },
        isWhtVisible() {
            return this.isTaxLoading || (Number(this.whtTax) >= 0 && this.totalWithdrawAmount);
        },
        totalWithdrawAmount() {
            let totalAmount = this.form.amount - this.whtTax || 0;
            const { feeAmount } = this.fee || {};

            return feeAmount && !this.isFreeWithdrawalAvailable ? totalAmount - feeAmount : totalAmount;
        },
        isAmountTypeValid() {
            return this.provider.fractionalSupported || (this.$v.form.amount && this.$v.form.amount.integer);
        },
        isFormAmountValid() {
            return !this.status.success && this.form.amount && this.isAmountTypeValid;
        },
        freeWithdrawalSummaryList() {
            return [
                {
                    id: 1,
                    infoLabel: this.$t('ui.payment.payout.withdrawalAmount', { currency: this.currencyFormat.symbol }),
                    amount: this.fee.amount,
                    classes: '',
                },
                {
                    id: 2,
                    infoLabel: this.$t('ui.payment.payout.withdrawalFeeAmount'),
                    amount: -this.fee.feeAmount,
                    classes: '',
                },
                {
                    id: 3,
                    infoLabel: this.$t('ui.payment.payout.withdrawalNetAmount'),
                    amount: this.fee.netAmount,
                    classes: 'bold',
                },
            ];
        },
        withdrawalSummaryList() {
            let list = [
                {
                    id: 1,
                    infoLabel: this.$t('ui.payment.payout.amountToWithdraw'),
                    amount: this.form.amount,
                    classes: '',
                    dataTestId: 'amountToWithdraw',
                    isLoading: false,
                    modalContent: '',
                },
                {
                    id: 2,
                    infoLabel: this.$t('ui.payment.payout.wht'),
                    amount: -this.whtTax,
                    classes: '',
                    dataTestId: 'whtTax',
                    isLoading: this.isTaxLoading,
                    modalContent: this.$t('ui.payment.payout.whtInfo'),
                },
                {
                    id: 3,
                    infoLabel: this.$t('ui.payment.payout.totalWithdrawAmount'),
                    amount: this.totalWithdrawAmount,
                    classes: 'bold',
                    dataTestId: 'finalWithdrawAmount',
                    isLoading: !this.totalWithdrawAmount || this.isTaxLoading,
                    modalContent: '',
                },
            ];

            return !this.isFreeWithdrawalAvailable && this.fee
                ? list.splice(2, 0, {
                      id: 4,
                      infoLabel: this.$t('ui.payment.payout.withdrawalFeeAmount'),
                      amount: -this.feeAmount,
                      classes: '',
                      dataTestId: 'amountToWithdraw',
                      isLoading: this.isTaxLoading,
                      modalContent: '',
                  })
                : list;
        },
        withdrawalFeeError() {
            const minimumWithdrawalAmount = this.provider.minAmount + this.fee.feeAmount;

            return this.$t('ui.payment.payout.error.withdrawalFeeError', {
                minimumWithdrawalAmount,
                minimumAmount: this.provider.minAmount,
                feeAmount: this.fee.feeAmount,
            });
        },
        canWithdrawWithFee() {
            if (!this.fee) return true;
            const minimumWithdrawalAmount = this.provider.minAmount + this.fee.feeAmount;
            return this.fee.amount >= minimumWithdrawalAmount;
        },
        isProvidersLoading() {
            return this.isLoading(action.FETCH_PAYOUT_PROVIDERS);
        },
        isAmountFieldDisabled() {
            return this.$isEmpty(this.provider);
        },
        amountInputAttr() {
            const { fractionalSupported } = this.provider;
            return attributesForNumberInput({ fractionalSupported, isIos: this.isIos });
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
        $isEmpty: helper.isEmpty,
        $isNumber: isNumber,
        setBank(value) {
            this.form.bank = value;
            this.$v.form.bank.$touch();
        },
        amountValidator(value) {
            return this.isCardless ? value < 1000 || value % 1000 === 0 : VALIDATORS.amount(value);
        },
        selectValidator() {
            this.$v.form.ghanaKYC.$touch();
            return this.showGhanaKYC ? !!this.form.ghanaKYC : true;
        },
        isRequiredField(name) {
            return [...DEFAULT_FIELDS, ...this.requiredFields].includes(name);
        },
        withdraw() {
            if (!this.isFreeWithdrawalAvailable && !this.canWithdrawWithFee) {
                this.isWithdrawalFeeError = true;
                return;
            }
            if (this.isPresto) {
                this.$v.$touch();
            }

            if (this.form.amount > this.balance) {
                this.$store.dispatch(auth.action.SET_ERROR, this.$t(`errors.${CANNOT_WITHDRAW_HAVE_NO_MONEY_ERROR}`));
                return;
            }

            if (
                this.paymentSettings.KYCEnabled &&
                !this.showGhanaKYC &&
                !this.preference.kycverified &&
                this.form.amount >= (this.paymentSettings.KYCThreshhold || 300)
            ) {
                this.showGhanaKYC = true;
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const { firstName, lastName, payoutType, name: payoutTypeName, isNameSet, channel } = this.provider;

            const fields = {
                firstName: this.form.firstName || firstName,
                lastName: this.form.lastName || lastName,
                amount: this.form.amount,
                BankAccountNumber: this.form.accountNumber,
                BankId: this.form.bank,
                location: this.form.location,
                channel,
                payoutType,
                payoutTypeName,
                isNameSet,
            };

            const payload = Object.keys(fields)
                .filter((field) => this.isRequiredField(field))
                .reduce((result, key) => ({ ...result, [key]: fields[key] }), {});

            if (this.showGhanaKYC) {
                this.$store.dispatch(auth.action.VERIFY_KYC, { documentType: this.form.ghanaKYC, documentId: this.form.ghanaId });
            } else {
                this.$store.commit(platformMutation.SET_KYC, { bypass: true, success: this.preference.kycverified }, { root: true });
            }

            this.$store.dispatch(action.WITHDRAW, {
                ...payload,
                ...(this.isExperimental && { password: this.form.password }),
            });
        },
        cancelPayout(id) {
            this.$store.dispatch(action.CANCEL_PAYOUT, id);
        },
        setAmount(value) {
            this.form.amount = value;
            this.isWithdrawalFeeError = false;
            this.fetchPayoutTax(value);
        },
        fetchPayoutTax(amount) {
            if (this.debouncedFetchTaxCallback && Number(amount)) {
                this.$store.commit(mutation.RESET_PAYOUT_TAX_AMOUNT);
                if (this.isAmountTypeValid) {
                    this.debouncedFetchTaxCallback(amount);
                }
            }
        },
        formatDateTime(dateTime) {
            const { time, date, month } = helper.formatDateTime(dateTime, {
                ...this.dateOptions,
                hour12: true,
                toObject: true,
                isUtc: true,
            });
            return `${date}/${month} - ${time.toUpperCase()}`;
        },
    },
    watch: {
        providers(list) {
            if (list && list.length) {
                const index =
                    list.length > 1
                        ? list.findIndex(({ paymentProvider }) => paymentProvider === this.preference.preferred_payment_provider)
                        : 0;
                const { payoutType } = list[index] || {};
                this.form.provider = index !== -1 ? { payoutType, index } : {};
                if (index !== -1) {
                    // watcher is needed because the $dirty flag is set after some time, not immediately after the value is set
                    this.stopFormProviderWatch = this.$watch('$v.form.provider.$dirty', (isDirty) => {
                        if (isDirty) {
                            this.stopFormProviderWatch();
                            // reset is necessary because otherwise, with a preselected value, the select is highlighted with a green border (the field has passed validation)
                            this.$v.form.provider.$reset();
                        }
                    });
                }
            }
        },
        provider(val) {
            this.isWithdrawalFeeError = false;
            if (this.$isEmpty(val)) {
                this.$v.form.$reset();
            } else if (this.$v.form.$invalid) {
                Object.keys(this.form).forEach((k) => {
                    const { $model } = this.$v.form[k] || {};
                    if ($model !== null && $model !== undefined && $model !== '') {
                        // call revalidation for all fields that have a value
                        this.$v.form[k].$touch();
                    }
                });
            }
            this.$emit('providerChange', val);
        },
    },
    beforeDestroy() {
        if (this.stopFormProviderWatch) {
            this.stopFormProviderWatch();
        }
        this.$store.dispatch(auth.action.RESET_ERROR);
    },
};
</script>

<style lang="scss" scoped>
%withdraw-notify {
    margin: 0;
    display: block;

    &-status {
        margin-bottom: 16px;
    }
}
.withdraw-component {
    > .notify {
        @extend %withdraw-notify;
    }

    h2 {
        @extend %h4-font-700;
        @include maxonecolumn {
            font-size: 1.26rem;
        }
    }

    .pending-requests {
        padding-top: 1px;
    }

    .pending-payout {
        @extend %body-normal-font-400;
        background: $withdraw-pending-requests-background;
        padding: 10px;
        display: table;
        width: 100%;

        .details,
        .cancel {
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            word-break: break-word;

            button {
                width: auto;
                margin: 0 0 0 auto;
                display: block;
            }
        }

        .date {
            padding-bottom: 3px;
        }
    }

    .reminder {
        position: relative;
        padding: 10px 0;
        background-color: $message-warning;
        text-align: center;
        @extend %h3-font-700;

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: calc(50% - 10px);
            top: 100%;
            border: 5px solid transparent;
            border-top-color: $message-warning;
        }
    }
}
.withdraw-form {
    .section-last-field {
        margin-bottom: 16px;
    }

    .notify {
        margin: 0 0 16px;
    }

    .text:not(.wht-summary) {
        padding: 0.8rem 1.2rem;
        background: $withdraw-form-text-background;
    }

    form {
        display: flex;
        flex-direction: column;
        padding: 16px 0 0;
    }

    // TODO: create SelectOptions component
    select {
        &.error {
            border-color: $error-red;
            border-width: $input-focus-error-border-width;

            &.warn {
                background: $message-warning;
            }
        }

        &.valid {
            border-color: $greenish;
            border-width: $input-border-width;
        }

        option {
            background: $white-bg;
        }
    }

    .column {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
    }

    select {
        border-color: $withdraw-select-border-color;
    }

    .wht-summary {
        padding-bottom: 16px;

        &-notify {
            margin: 0 0 10px 0;
        }

        .info {
            cursor: pointer;
        }

        .amount {
            color: $main-text;
        }
    }
}
.withdraw-form-wrapper {
    background: $payment-component-background;
    padding: 12px;
    margin: 10px 0;

    > .notify {
        @extend %withdraw-notify;
        margin-top: 16px;
    }

    ::v-deep .user-phone {
        padding: 12px;
        margin: 0;
    }
}
.withdraw-phone {
    border: 1px solid #e6e7e2;
    margin-top: 0;
}

.withdraw-amount {
    ::v-deep {
        .optional-slot {
            color: inherit;
        }
        .input-field-wrapper {
            @include only_mini_feature_phone {
                display: table-row;
            }
        }
    }
}

.withdraw-fee {
    position: relative;
    display: flex;
    align-items: center;

    ::v-deep .label-text {
        @extend %small-details-font-400;
    }

    .withdraw-fee-info-modal {
        width: inherit;
        left: auto;
        right: -7px;
        bottom: 15px;
        @extend %body-normal-font-400;
    }

    .withdraw-fee-info-icon {
        margin-left: 4px;
    }

    ::v-deep .modal {
        &-container {
            padding-bottom: 16px;
        }
        &-body {
            padding-top: 0;
        }
        &-header-button {
            &.no-title {
                margin: 8px 8px 0 0;
                svg {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}

.summary-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;

    .currency {
        height: 14px;
    }

    .wht-info-modal {
        top: 12px;
        left: -8px;
        justify-content: flex-start;

        ::v-deep {
            .modal-body {
                padding: 0 16px;
            }

            .modal-container {
                padding-bottom: 16px;
            }
        }
    }
}
</style>
