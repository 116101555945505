<template>
    <div v-if="progressiveJackpot">
        <slot :progressiveJackpot="{ ...progressiveJackpot, prize }" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { action as platformAction, getter as platformGetter } from '@/modules/platform';
import { progressiveJpType, progressiveJpPagePath, progressiveJpTypes } from '@/modules/platform/const/progressive-jackpot';
import { helper } from '@/modules/core';
import { getter as translationsGetter } from '@/store/modules/translations';
import sportBg from '@/assets/images/backgrounds/progressive-jp-sport-bg.png';
import casinoBg from '@/assets/images/backgrounds/progressive-jp-casino-bg.png';
import { DEFAULT_PROGRESSIVE_JP_POLLING_TIME } from '@/modules/platform/store';

const DEFAULT_PROGRESSIVE_JP_PRIZE_CHANGING_MILLISECONDS = 1000;

export default {
    name: 'ProgressiveJpProvider',
    props: {
        type: {
            type: String,
            validator(val) {
                return progressiveJpTypes.includes(val);
            },
        },
    },
    data() {
        return {
            prize: 0,
        };
    },
    computed: {
        ...mapGetters({
            progressiveJackpots: platformGetter.GET_PROGRESSIVE_JP,
            settings: platformGetter.GET_SETTINGS,
            currentLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
            taxSportRate: platformGetter.GET_TAX_AMOUNT,
            taxCasinoRate: platformGetter.GET_CASINO_TAX_AMOUNT,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        generalConfig() {
            return {
                [progressiveJpType.SPORT]: {
                    background: sportBg,
                    cta: this.$t('ui.pjp.sportCta'),
                    icon: 'icon-ball-with-shadow',
                    tax: this.taxSportRate * 100,
                },
                [progressiveJpType.CASINO]: {
                    background: casinoBg,
                    cta: this.$t('ui.pjp.casinoCta'),
                    icon: 'icon-casino',
                    tax: this.taxCasinoRate * 100,
                },
            };
        },
        progressiveJackpot() {
            let progressiveJP;
            if (!this.type) {
                progressiveJP =
                    this.progressiveJackpots[progressiveJpType.SPORT].active[0] ||
                    this.progressiveJackpots[progressiveJpType.CASINO].active[0];
            } else {
                progressiveJP = this.progressiveJackpots[this.type].active[0];
            }
            if (!progressiveJP) return null;
            const { displayNames = [], jackpotType, amount, jackpotFrequency, uuid } = progressiveJP;
            const displayNameByCurrentLanguage = displayNames.find((it) => it.language === this.currentLanguage);
            const displayNameByDefaultLanguage = displayNames.find((it) => it.language === 'en');
            const name = helper.getObjectField(displayNameByCurrentLanguage || displayNameByDefaultLanguage, 'name', '');

            return {
                ...this.generalConfig[jackpotType],
                name,
                type: jackpotType,
                prize: amount,
                frequency: jackpotFrequency,
                link: progressiveJpPagePath[jackpotType],
                uuid,
            };
        },
        isProgressiveJackpotEnabled() {
            const jackpots = helper.getObjectField(this.settings, 'progressiveJackpot', []);
            return (!this.type && jackpots.length) || jackpots.some((it) => it.type.toLowerCase() === this.type);
        },
        progressiveJackpotPrizeChangingMilliseconds() {
            return helper.getObjectField(
                this.brandPreference,
                'progressiveJackpotPrizeChangingMilliseconds',
                DEFAULT_PROGRESSIVE_JP_PRIZE_CHANGING_MILLISECONDS
            );
        },
    },
    methods: {
        ...mapActions({
            pollActiveProgressiveJackpots: platformAction.POLL_ACTIVE_PROGRESSIVE_JACKPOTS,
        }),
    },
    watch: {
        progressiveJackpot: {
            immediate: true,
            handler(jackpot, oldJackpot) {
                if (!jackpot) {
                    return;
                }
                if (!oldJackpot || jackpot.prize === oldJackpot.prize || oldJackpot.uuid !== jackpot.uuid) {
                    this.prize = jackpot.prize;
                    return;
                }
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }
                this.intervalId = setInterval(() => {
                    if (this.prize < jackpot.prize) {
                        this.prize +=
                            (jackpot.prize - oldJackpot.prize) /
                            (DEFAULT_PROGRESSIVE_JP_POLLING_TIME / this.progressiveJackpotPrizeChangingMilliseconds);
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, this.progressiveJackpotPrizeChangingMilliseconds);
            },
        },
    },
    created() {
        if (this.isProgressiveJackpotEnabled) {
            this.pollActiveProgressiveJackpots();
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
};
</script>
