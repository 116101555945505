export const routeName = {
    ACCOUNT_VERIFICATION: 'Account Verification',
    BETSLIP: 'Betslip',
    CASHOUT_LOCKED: 'CashoutLocked',
    CASINO: 'Casino',
    CASINO_GAME: 'casinoGame',
    CHANGE_PASSWORD: 'ChangePassword',
    COUNTRY: 'Country',
    DEPOSIT: 'Deposit',
    EVENT: 'Event',
    EVENT_NOT_FOUND: 'EventNotFound',
    GAMES: 'Games',
    GENERIC_PAGE: 'GenericPage',
    GROUP: 'Group',
    HOMEPAGE: 'Homepage',
    JACKPOT_PAGE: 'JackpotPage',
    JACKPOT_TICKET: 'Jackpot Ticket',
    JOIN_NOW: 'JoinNow',
    LANDING: 'LandingPage',
    LIVE: 'Live',
    LIVE_CATEGORY: 'LiveCategory',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    MATCH_DAY: 'Matchday',
    MESSAGES: 'Messages',
    MTN_AIRTIME_BANNER: 'MTN_AIRTIME_BANNER',
    MY_ACCOUNT: 'MyAccount',
    MY_BETS: 'My Bets',
    NOT_FOUND: '404',
    PAWA6: 'pawa6',
    POPULAR: 'Popular',
    PRESTO_SELF_EXCLUSION: 'PrestoSelfExclusion',
    PREVIEW: 'preview',
    RESET: 'Reset',
    RESET_PASSWORD: 'Reset Password',
    RULES: 'Rules',
    RULES_PRESTO: 'Rules presto',
    SEARCH: 'Search',
    SET_PASSWORD: 'Set Password',
    SHARE: 'Share',
    SIGNUP: 'Registration',
    SMS_VERIFICATION: 'SMS Verification',
    SNAPSHOT_BETSLIP: 'Snapshot Betslip',
    STATEMENT: 'Statement',
    UPCOMING: 'Upcoming',
    VERIFY_PAGE: 'VerifyPage',
    VIRTUAL_SPORTS: 'Virtual Sports',
    WITHDRAW: 'Withdraw',
    WITHDRAW_DISABLED: 'WithdrawDisabled',
    BOOSTED_EVENTS: 'BoostedEvents',
};
