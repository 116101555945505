import Vue from 'vue';
import { helper, deviceType, actionLoader, action as coreAction } from '@agi.packages/core';
import { auth, getter as platformGetter, GTM_EVENT_SERVER_ERROR_MESSAGES } from '@agi.packages/platform';
import { DEFAULT_EVENT_QUERY_AMOUNT, marketPreferenceType, jackpotRequestType, widgetType } from '@agi.packages/sport';
import { getEventWidget } from '@agi.packages/sport/utils/event/get-event-widget';

import { getLocalTypes } from '@/store/utils'; // move to core BP-16141
import { getter as transGetter } from '@/store/modules/translations';

import betslip from './modules/betslip';
import { pool, pricing, betslip as betslipEndpoints, sportsbook } from '../endpoints';
import { ROUND_STATUS, VIRTUAL_LEAGUE_MAP, VIRTUAL_UPCOMING_LEAGUE_MAP } from '../const/virtual-sports';
import {
    CASHOUT_OFFER_ERRORS,
    CASHOUT_OFFER_DEAD_ERRORS,
    CASHOUT_OFFER_LOCKED_ERRORS,
    CASHOUT_OFFER_NOT_CASHOUTABLE,
    CASHOUT_CONFIRM_OFFER_RUN_POLLING_ERRORS,
    AUTO_CASHOUT_OFFER_LOCKED_ERRORS,
    AUTO_CASHOUT_OFFER_DEAD_ERRORS,
    AUTO_CASHOUT_OFFER_ERRORS,
    AUTO_CASHOUT_OFFER_OUT_OF_RANGE_ERROR,
} from '../const/errors';

import {
    processSportsResponse,
    processRawEvent,
    groupMarkets,
    sortMarkets,
    convertStringToArrayOfIntegers,
    processJackPotTicket,
    processJackpotList,
} from './utils';

const CASHOUT_STATUS_CHECK_TIMEOUT = 1000;

export const myBetsType = {
    VIRTUAL: 'virtual',
    PENDING: 'pending',
    SETTLED: 'settled',
    JACKPOT: 'jackpot',
};

export const sportType = {
    SPORT: 'sport',
    VIRTUAL: 'virtual-sport',
};

export const marketTypeCategory = {
    ALL: 'ALL_MARKETS',
    MAIN: 'MAIN_MARKET',
    NONE: 'NO_MARKETS',
};

export const mutation = {
    SET_SPORT_TYPE: 'sport/setSportType',
    SET_IN_PLAY_DETAILS: 'sport/setInPlayDetails',
    SET_STATS_PERFORM: 'sport/setStatsPerform',
    SET_SPORT_RADAR_JWT: 'sport/setSportRadarJWT',
    UPDATE_SPORTS: 'sport/updateSports',
    APPEND_UPCOMING: 'sport/appendUpcoming',
    SET_TOURNAMENTS: 'sport/setTournaments',
    SET_COUNTRIES: 'sport/setCountries',
    REMOVE_UPCOMING_EVENTS: 'sport/removeUpcomingEvents',
    SET_SINGLE_EVENT: 'sport/setSingleEvent',
    CONTAINS_HOT: 'sport/containsHot',
    RESET_SINGLE_EVENT_ERROR: 'sport/resetSingleEventError',
    RESET_SPORTS_ERROR: 'sport/resetSportsError',
    SET_JACKPOT_SELECTED: 'sport/setJackpotSelected',
    RESET_JACKPOT_ERROR: 'sport/resetJackpotError',
    SET_VIRTUAL_ROUNDS: 'sport/setVirtualRounds',
    SET_VIRTUAL_EVENTS: 'sport/setVirtualEvents',
    RESET_VIRTUAL_EVENTS: 'sport/resetVirtualEvents',
    SET_VIRTUAL_STANDINGS: 'sport/setVirtualStandings',
    RESET_VIRTUAL_STANDINGS: 'sport/resetVirtualStandings',
    SET_VIRTUAL_SEASONS: 'sport/setVirtualSeasons',
    SET_VIRTUAL_SEASON: 'sport/setVirtualSeason',
    SET_VIRTUAL_SPORTS_ERROR: 'sport/setVirtualSportsError',
    SET_VIRTUAL_MATCHUPS: 'sport/setVirtualMatchups',
    RESET_VIRTUAL_MATCHUPS: 'sport/resetVirtualMatchups',
    SET_AUTO_CASHOUT_OFFER: 'sport/setAutoCashoutOffer',
    CLEAR_AUTO_CASHOUT_OFFER: 'sport/clearAutoCashoutOffer',
    CLEAR_AUTO_CASHOUT_OFFER_ERROR: 'sport/clearAutoCashoutOfferError',
    SET_CASHOUT_OFFER: 'sport/setRequestCashoutOffer',
    SET_CASHOUT_OFFER_ERROR: 'sport/setCashoutOfferError',
    SET_AUTO_CASHOUT_OFFER_ERROR: 'sport/setAutoCashoutOfferError',
    UPDATE_CASHOUT_POLLING: 'sport/updateCashoutPolling',
    SET_CASHOUT_STATUS: 'sport/setCashoutStatus',
    GENERATE_JACKPOT_UUID: 'sport/generateJackpotUUID',
    TOGGLE_STATISTIC: 'sport/toggleStatistic',
    SET_IS_LIVE_STREAM_AVAILABLE: 'sport/setIsLiveStreamAvailable',
    UPDATE_SINGLE_EVENT_ODDS: 'sport/updateSingleEventOdds',
    UPDATE_SINGLE_EVENT_SCOREBOARD: 'sport/updateSingleEventScoreboard',
    SET_SAVED_EVENT_FILTERS: 'sport/setSavedEventFilters',
    SET_IS_CONFIRM_CASHOUT_READY: 'sport/setIsConfirmCashOutReady',
    SET_IS_CASHOUT_REFRESHING: 'sport/setIsCashOutRefreshing',
};

export const action = {
    AUTHORIZE_STATS_PERFORM: 'sport/authorizeStatsPerform',
    AUTHORIZE_SPORT_RADAR: 'sport/authorizeSportRadar',
    CREATE_AUTO_CASHOUT_OFFER: 'sport/createAutoCashoutOffer',
    LOAD_UPCOMING_EVENTS: 'sport/loadUpcomingEvents',
    GET_EVENTS_BY_QUERY: 'sport/getEventsByQuery',
    GET_EVENTS_BY_IDS: 'sport/getEventsByIds',
    GET_SINGLE_EVENT_BY_ID: 'sport/getSingleEventsById',
    GET_UPCOMING_LIST: 'sport/getUpcomingList',
    REMOVE_UPCOMING_EVENTS: 'sport/removeUpcomingEvents',
    GET_JACKPOTS: 'sport/getJackpots',
    GET_JACKPOT_POOL: 'sport/getJackpotPool',
    GET_JACKPOT_TICKET: 'sport/getJackpotTicket',
    ADD_JACKPOT_TICKET: 'sport/addJackpotTicket',
    SET_JACKPOT_ERROR: 'sport/setJackpotError',
    GENERATE_JACKPOT_UUID: 'sport/generateJackpotUUID',
    GET_LIVE_EVENTS_V2: 'sport/getLiveEventsV2',
    GET_SEARCH_RESULTS: 'sport/getSearchResults',
    GET_JACKPOT_BETSLIPS: 'sport/getJackpotBetslips',
    GET_SETTLED_BETSLIPS: 'sport/getSettledBetslips',
    GET_VIRTUAL_BETSLIPS: 'sport/getVirtualBetslips',
    GET_PENDING_BETSLIPS: 'sport/getPendingBetslips',
    RESET_MY_BETS: 'sport/resetMyBets',
    GET_SINGLE_BETSLIP: 'sport/getSingleBetslip',
    GET_DETAIL_BETSLIP: 'sport/getDetailBetslip',
    GET_IN_PLAY_LIST: 'sport/getInPlayList',
    RESET_SPORTS_ERROR: 'sport/resetSportsError',
    GET_VIRTUAL_ROUNDS: 'sport/getVirtualRounds',
    GET_VIRTUAL_LIVE_ROUND: 'sport/getVirtualLiveRound',
    GET_VIRTUAL_STANDINGS: 'sport/getVirtualStanding',
    GET_VIRTUAL_MATCHUPS: 'sport/getVirtualMatchups',
    GET_BETSLIP_AUTO_CASHOUT_OFFER: 'sport/getBetslipAutoCashoutOffer',
    REQUEST_CASHOUT_OFFER: 'sport/requestCashoutOffer',
    REQUEST_CASHOUT_OFFER_AUTO: 'sport/requestCashoutOfferAuto',
    CONFIRM_CASHOUT_OFFER: 'sport/confirmCashoutOffer',
    CHECK_CASHOUT_OFFER_STATUS: 'sport/checkCashoutOfferStatus',
    START_CASHOUT_POLLING: 'sport/startCashoutPolling',
    GET_VIRTUAL_EVENTS_BY_ROUND_ID: 'sport/getVirtualEventsByRoundId',
    GET_EVENT_WITH_PRICES_BY_ID: 'sport/getEventWithPricesById',
    UPDATE_SAVED_EVENT_FILTERS: 'sport/updateSavedEventFilters',
};

export const getter = {
    GET_AUTO_CASHOUT_OFFER: 'sport/getAutoCashOutOffer',
    GET_EVENT_BY_ID: 'sport/getEventById',
    GET_MARKETS_BY_ID: 'sport/getMarketsById',
    GET_EVENT_WITH_MARKETS_BY_ID: 'sport/getEventWihMarketsById',
    GET_JACKPOT_BETS: 'sport/getJackpotBets',
    GET_PENDING_BETS: 'sport/getPendingBets',
    GET_SETTLED_BETS: 'sport/getSettledBets',
    GET_VIRTUAL_BETS: 'sport/getVirtualBets',
    GET_SPORT_TYPE: 'sport/getSportType',
    IS_VIRTUAL_SPORT: 'sport/isVirtualSport',
    GET_UPCOMING_VIRTUAL_ROUND_INDEX: 'sport/getUpcomingVirtualRoundIndex',
    GET_JACKPOT_POOL: 'sport/getJackpotPool',
    GET_JACKPOT_UUID: 'sport/getJackpotUUID',
    GET_LOCKED_MARKETS: 'sport/getLockedMarkets',
    GET_SELECTED_VIRTUAL_SEASON: 'sport/getSelectedVirtualSeason',
    GET_SPORT_RADAR_JWT: 'sport/getSportRadarJWT',
    GET_SPORT_EVENTS_COUNTER: 'sport/getSportEventsCounter',
    SAVED_EVENT_FILTERS: 'sport/savedEventFilters',
    GET_AUTO_CASHOUT_OFFER_ERRORS: 'sport/getAutoCashOutOfferErrors',
    GET_CASHOUT_OFFER_ERRORS: 'sport/getCashOutOfferErrors',
    GET_IS_CONFIRM_CASHOUT_READY: 'sport/getIsConfirmCashOutReady',
    GET_IS_CASHOUT_REFRESHING: 'sport/getIsCashOutRefreshing',
    GET_CASHOUT_AMOUNT_OFFER: 'sport/getCashOutAdjustedAmount',
    GET_BOOSTED_EVENTS: 'sport/getBoostedEvents',
    EVENTS_COUNT_PER_TYPE: 'sport/eventsCountPerType',
};

const JACKPOT_STATE = {
    active: [],
    pool: null,
    resulted: [],
    error: null,
    selected: [],
    uniqueTicketId: null,
};

const MY_BETS_STATE = {
    [myBetsType.VIRTUAL]: {},
    [myBetsType.SETTLED]: {},
    [myBetsType.PENDING]: {},
    [myBetsType.JACKPOT]: {},
    betslip: null,
    autoCashOut: null,
    events: [],
    error: null,
    offerPolling: {
        pollingEnabled: null,
        pollingSeconds: null,
        requestTimestamp: 0,
        requestLimit: null,
        type: '',
        initialRequestLimit: null,
    },
    offerError: {
        dead: null,
        refresh: null,
        message: null,
        isLockedError: false,
        isGenericError: false,
        isNetworkError: false,
        illegalSelections: [],
    },
    autoCashOutOfferError: {},
    offerStatus: { statusId: null, confirmed: false, pollingCounter: null },
};

const MATCHUPS_STATE = {
    leagues: [],
    teams: {},
    rounds: {},
};

const state = () => ({
    ui: {
        sportType: null,
        afterBetPlaced: false,
        savedEventFilters: {},
    },
    virtualSports: {
        rounds: [],
        seasons: [],
        events: {
            upcoming: {},
            live: {},
        },
        season: {},
        standings: null,
        matchups: { ...MATCHUPS_STATE },
        error: null,
        errorCode: null,
    },
    sports: {
        events: [],
        eventsByCategoryId: {},
        sortedEvents: {},
        livePopular: [],
        liveCategories: [],
        liveEventsCount: undefined,
        boostedEventsCount: undefined,
        boosted: [],
        moreEvents: {},
        remainingEventIds: [],
        priority: 0,
        resultsName: null,
        containsHot: false,
        singleEvent: {
            [marketTypeCategory.ALL]: {},
            [marketTypeCategory.MAIN]: {},
            [marketTypeCategory.NONE]: {},
            list: {}, // 'eventId': { ...event, markets: ['market_id'] }
            markets: {}, // 'marketId': 'eventId': [...markets]
            errors: [],
        },
        groups: [],
        participants: [],
        owner: null,
        error: null,
        errorCode: null,
        regionId: null,
        statsPerform: {
            accessToken: null,
            expirationDate: null,
            outletAuthKey: null,
        },
        srJWT: '',
    },
    myBets: { ...MY_BETS_STATE },
    jackpot: { ...JACKPOT_STATE },
    isStatisticOpen: false,
    isLiveStreamAvailable: false,
});

const _getter = getLocalTypes(getter);

const getters = {
    [_getter.GET_EVENT_BY_ID]: (state) => (id) => state.sports.singleEvent.list[id],
    [_getter.GET_MARKETS_BY_ID]: (state) => (id) => state.sports.singleEvent.markets[id],
    [_getter.GET_EVENT_WITH_MARKETS_BY_ID]: (state) => (payload) => {
        const { id, market } = payload;
        const event = state.sports.singleEvent.list[id];
        const rawEventMarkets = state.sports.singleEvent.markets[id] || [];
        const eventMarkets = rawEventMarkets.filter(({ prices }) => !prices.some(({ suspended, price }) => suspended || !price));
        let markets;
        switch (market) {
            case marketTypeCategory.ALL:
                markets = eventMarkets;
                break;
            case marketTypeCategory.MAIN:
                const mainMarketIds = state.sports.singleEvent[marketTypeCategory.MAIN][id];
                markets = eventMarkets && eventMarkets.filter(({ marketType }) => mainMarketIds && mainMarketIds.includes(marketType.id));
                break;
            case marketTypeCategory.NONE:
                markets = [];
                break;
            default:
                const marketIds = market && convertStringToArrayOfIntegers(market, true);
                markets = marketIds
                    ? eventMarkets && eventMarkets.filter((_market) => marketIds.includes(_market.marketType?.id))
                    : eventMarkets;
        }
        return event && markets && { ...event, markets: groupMarkets(markets) };
    },
    sliceUpcomingRemainingEvents: (state) => (amount) => state.sports.remainingEventIds.slice(0, amount),
    [_getter.GET_JACKPOT_BETS]: (state) => state.myBets.jackpot,
    [_getter.GET_PENDING_BETS]: (state) => state.myBets.pending,
    [_getter.GET_SETTLED_BETS]: (state) => state.myBets.settled,
    [_getter.GET_VIRTUAL_BETS]: (state) => state.myBets.virtual,
    [_getter.GET_SPORT_TYPE]: (state) => state.ui.sportType,
    [_getter.IS_VIRTUAL_SPORT]: (state) => state.ui.sportType === sportType.VIRTUAL,
    [_getter.GET_UPCOMING_VIRTUAL_ROUND_INDEX]: (state) => {
        for (let f = 0; f < state.virtualSports.rounds.length; f++) {
            if (state.virtualSports.rounds[f].bettingClosesTime > Math.round(new Date().getTime())) {
                return f;
            }
        }
    },
    [_getter.GET_JACKPOT_POOL]: ({ jackpot }) => {
        const { events = [], rows = [] } = jackpot.pool || {};
        return (events.length && jackpot.pool) || (rows.length && { ...jackpot.pool, events: rows }) || null;
    },
    [_getter.GET_JACKPOT_UUID]: (state) => state.jackpot.uniqueTicketId,
    [_getter.GET_LOCKED_MARKETS]: (state) => state.myBets.offerError.illegalSelections || [],
    [_getter.GET_SELECTED_VIRTUAL_SEASON]: (state) => state.virtualSports.season,
    [_getter.GET_SPORT_RADAR_JWT]: (state) => state.sports.srJWT,
    [_getter.GET_SPORT_EVENTS_COUNTER]: (state) => state.sports.moreEvents,
    [_getter.SAVED_EVENT_FILTERS]: (state, getters, rootState, rootGetters) => {
        const { isEventPageFiltersEnabled } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return isEventPageFiltersEnabled ? state.ui.savedEventFilters : {};
    },
    [_getter.GET_AUTO_CASHOUT_OFFER]: (state) => state.myBets.autoCashOut,
    [_getter.GET_IS_CONFIRM_CASHOUT_READY]: (state) => state.myBets.betslip.isConfirmCashOutReady || false,
    [_getter.GET_IS_CASHOUT_REFRESHING]: (state) => state.myBets.betslip.isCashOutRefreshing || false,
    [_getter.GET_CASHOUT_AMOUNT_OFFER]: (state) => helper.getObjectField(state, 'myBets.autoCashOut.amountOffer', 0),
    [_getter.GET_CASHOUT_OFFER_ERRORS]: (state) => state.myBets.offerError,
    [_getter.GET_AUTO_CASHOUT_OFFER_ERRORS]: (state) => state.myBets.autoCashOutOfferError || {},
    [_getter.GET_BOOSTED_EVENTS]: (state) => state.sports.boosted,
    [_getter.EVENTS_COUNT_PER_TYPE]: (state) => ({
        boostedEventsCount: state.sports.boostedEventsCount,
        liveEventsCount: state.sports.liveEventsCount,
    }),
};

const _action = getLocalTypes(action);

const actions = {
    [_action.AUTHORIZE_STATS_PERFORM]({ state, commit }, streamUuid) {
        Vue.$http.post(sportsbook.authorizeStatsPerform, { streamUuid }).then(({ data }) => {
            commit(_mutation.SET_STATS_PERFORM, data);
        });
    },
    [_action.AUTHORIZE_SPORT_RADAR]({ commit }) {
        return Vue.$http
            .post(sportsbook.authorizeSportRadar)
            .then(({ data }) => {
                commit(_mutation.SET_SPORT_RADAR_JWT, data.jwt);
            })
            .catch((error) => console.error(`${action.AUTHORIZE_SPORT_RADAR} Response Error`, [error]));
    },
    [_action.LOAD_UPCOMING_EVENTS]: actionLoader(action.LOAD_UPCOMING_EVENTS, ({ commit, rootGetters }, { sportParams }) => {
        const currentUserStatus = rootGetters[platformGetter.GET_CURRENT_USER_STATUS];
        const requests = sportParams.eventParams
            .filter((eventParam) => helper.hasValidVisibility(eventParam.visibility, currentUserStatus))
            .map(({ visibility, ...rest }) => rest);

        const query = encodeURIComponent(JSON.stringify({ requests }));
        return Vue.$http
            .get(`${sportsbook.getEventsListsByQueryString}?q=${query}`)
            .then((response) => processSportsResponse(response, false, sportParams.LivePopular))
            .then((response) => commit(_mutation.UPDATE_SPORTS, { ...response }))
            .catch((error) => console.error(`${action.LOAD_UPCOMING_EVENTS} Response Error`, [error]));
    }),
    [_action.GET_EVENTS_BY_QUERY]: actionLoader(action.GET_EVENTS_BY_QUERY, ({ commit }, payload) => {
        const query = encodeURIComponent(
            JSON.stringify({
                ...{
                    onlyPopular: false,
                    marketFilters: {
                        marketPreference: marketPreferenceType.MAIN,
                    },
                    take: DEFAULT_EVENT_QUERY_AMOUNT,
                    competitions: [], // API Fails if empty array is not provided
                },
                ...payload,
            })
        );
        return Vue.$http
            .get(`${sportsbook.getEventsByQueryString}?q=${query}`)
            .then(({ data }) => {
                const { events, nextEventIds } = data;
                const sortedEvents = sortMarkets(events);
                commit(_mutation.UPDATE_SPORTS, { events: sortedEvents, remainingEventIds: nextEventIds });
            })
            .catch((error) => console.error(`${action.GET_EVENTS_BY_IDS} Response Error`, [error, { payload }]));
    }),
    [_action.GET_EVENTS_BY_IDS]: actionLoader(action.GET_EVENTS_BY_IDS, ({ commit }, payload) => {
        return Vue.$http
            .post(sportsbook.getEventsByIds, payload)
            .then(({ data }) => {
                const { items } = data;
                const sortedEvents = sortMarkets(items);
                commit(_mutation.APPEND_UPCOMING, sortedEvents);
            })
            .catch((error) => console.error(`${action.GET_EVENTS_BY_IDS} Response Error`, [error, { payload }]));
    }),
    [_action.GET_SINGLE_EVENT_BY_ID]: actionLoader(action.GET_SINGLE_EVENT_BY_ID, ({ commit, state }, payload) => {
        return Vue.$http
            .post(sportsbook.getEventsByIds, payload)
            .then(({ data }) => data)
            .then(({ items }) => {
                const [data] = items;
                const { eventIds, marketFilters } = payload;
                const [eventId] = eventIds;
                const categoryMap = {
                    [marketPreferenceType.MAIN]: marketTypeCategory.MAIN,
                    [marketPreferenceType.ALL]: marketTypeCategory.ALL,
                    [marketTypeCategory.NONE]: marketTypeCategory.NONE,
                };
                const marketId = categoryMap[marketFilters?.marketPreference] || marketTypeCategory.ALL;
                const markets = state.sports.singleEvent.markets[eventId] || [];
                return processRawEvent({ eventId, marketId, data }, { markets });
            })
            .then((payload) => commit(_mutation.SET_SINGLE_EVENT, payload))
            .catch((error) => console.error(`${action.GET_SINGLE_EVENT_BY_ID} Response Error`, [error, { payload }]));
    }),
    [_action.REMOVE_UPCOMING_EVENTS]: actionLoader(action.REMOVE_UPCOMING_EVENTS, ({ commit }, payload) => {
        return commit(_mutation.REMOVE_UPCOMING_EVENTS, payload);
    }),
    [_action.GET_EVENT_WITH_PRICES_BY_ID]: actionLoader(action.GET_EVENT_WITH_PRICES_BY_ID, ({ state, commit }, eventId) => {
        const marketId = marketTypeCategory.ALL;
        return Vue.$http
            .get(`${sportsbook.getEventWithPricesById}/${eventId}`)
            .then(({ data }) => data.Data || data)
            .then((data) => {
                const markets = state.sports.singleEvent.markets[eventId] || [];
                if (!markets.length) {
                    Vue.$gtm.query({
                        event: 'event_empty_states',
                        eventId,
                    });
                }
                return processRawEvent({ eventId, marketId, data }, { markets });
            })
            .then((payload) => commit(_mutation.SET_SINGLE_EVENT, payload))
            .catch(({ errorCode, statusCode, message, config }) => {
                const code = errorCode || statusCode;
                commit('setSingleEventError', {
                    message: helper.processErrorResponse({ message }),
                    eventId,
                    marketId,
                    errorCode: code,
                });
                if (GTM_EVENT_SERVER_ERROR_MESSAGES[code] || !code) {
                    Vue.$gtm.query({
                        event: GTM_EVENT_SERVER_ERROR_MESSAGES[code] || GTM_EVENT_SERVER_ERROR_MESSAGES['get_event_prices_by_id'],
                        url: helper.getObjectField(config, 'url'),
                    });
                }
            });
    }),
    [_action.GET_LIVE_EVENTS_V2]: actionLoader(action.GET_LIVE_EVENTS_V2, ({ commit }, { sportParams }) => {
        return Vue.$http
            .get(sportsbook.getAllLiveEvents)
            .then(({ data }) => data.Data || data)
            .then(({ responses }) => processSportsResponse(responses, false, sportParams.LivePopular))
            .then(({ liveEvents, livePopular }) => {
                commit(_mutation.UPDATE_SPORTS, { liveEvents, livePopular });
            })
            .catch((error) => commit('setSportsError', { error, message: helper.processErrorResponse(error) }));
    }),
    [_action.RESET_SPORTS_ERROR]({ commit, state }) {
        if (state.sports.error) {
            commit(_mutation.RESET_SPORTS_ERROR);
        }
    },
    [_action.GET_SINGLE_BETSLIP]: actionLoader(action.GET_SINGLE_BETSLIP, ({ commit, dispatch, rootGetters }, id) => {
        return Vue.$http
            .get(`${betslipEndpoints.getSingleBetslip}/${id}`)
            .then(({ data }) => data.Data || data)
            .then((data) => {
                commit('setSingleBetslip', data);
                commit(_mutation.SET_CASHOUT_OFFER, null);
                const { isMyBetsLiveScoreEnabled } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
                if (isMyBetsLiveScoreEnabled) dispatch(_action.GET_IN_PLAY_LIST);
                if (data.cashoutInfo?.offerErrorCode) {
                    const code = data.cashoutInfo?.offerErrorCode;
                    // TODO: make dynamic errors for presto and android
                    const { locales } = rootGetters[transGetter.LANGUAGE];
                    const message = locales[Object.keys(locales)[0]].errors.global;
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                        refresh: false,
                        isGenericError: !CASHOUT_OFFER_ERRORS.includes(code),
                        dead: CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                        message: message[code],
                        isLockedError: CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                        isNetworkError: !code,
                    });
                }
            })
            .catch((error) => {
                commit('setMyBetsError', helper.processErrorResponse(error, 'ui.common.error.failedToLoadBets'));
            });
    }),
    [_action.GET_IN_PLAY_LIST]: actionLoader(action.GET_IN_PLAY_LIST, ({ commit }) => {
        return Vue.$http
            .get(`${betslipEndpoints.getInPlayList}`)
            .then(({ data }) => {
                const { items = [] } = data;
                commit(_mutation.SET_IN_PLAY_DETAILS, items);
            })
            .catch((error) => commit('setMyBetsError', error.message));
    }),
    [_action.GET_DETAIL_BETSLIP]: actionLoader(action.GET_DETAIL_BETSLIP, ({ commit }, id) => {
        return Vue.$http
            .post(`${betslipEndpoints.getDetailBetslip}`, { id })
            .then(({ data }) => data.Data || data)
            .then(({ details }) => {
                commit('setSingleBetslip', details);
            });
    }),
    // TODO: refactor jackpot store logic
    [_action.GET_JACKPOTS]: actionLoader(
        action.GET_JACKPOTS,
        ({ state, commit, dispatch, rootGetters }, { requestType: type, loadResults = false }) => {
            const query = new URLSearchParams('');
            if (type === jackpotRequestType.ACTIVE) {
                query.set('take', '100');
            }
            return dispatch(
                coreAction.WATCHER,
                [
                    () => rootGetters[platformGetter.GET_BRAND_PREFERENCE],
                    ({ jurisdictionId }) =>
                        Vue.$http
                            .get(`${pool.getJackpots}/${type}?${query.toString()}`, { headers: { jurId: jurisdictionId } })
                            .then(({ data }) => {
                                const { list } = data;
                                if (list && list.length === 1) {
                                    dispatch(_action.GET_JACKPOT_POOL, list.pop()?.pool?.id);
                                } else if (!list.length && type === jackpotRequestType.ACTIVE && loadResults) {
                                    dispatch(_action.GET_JACKPOTS, { requestType: jackpotRequestType.RESULTS });
                                } else {
                                    commit('setJackpots', { [type]: processJackpotList(list, jurisdictionId) });
                                }
                            })
                            .catch((error) => console.error(`${action.GET_JACKPOTS} Response Error`, [error, type])),
                ],
                { root: true }
            );
        }
    ),
    [_action.GET_JACKPOT_POOL]: actionLoader(action.GET_JACKPOT_POOL, ({ commit, dispatch, rootGetters }, poolId) => {
        const error = Vue.$t('ui.jackpot.error.jackpotMissing');
        return dispatch(
            coreAction.WATCHER,
            [
                () => rootGetters[platformGetter.GET_BRAND_PREFERENCE],
                ({ jurisdictionId }) =>
                    Vue.$http
                        .get(`${pool.getJackpots}/${poolId}`, { headers: { jurId: jurisdictionId } })
                        .then(({ data }) => {
                            const pool = processJackpotList([data], jurisdictionId).pop();
                            commit('setJackpots', { ...((pool && { pool }) || { error }) });
                        })
                        .catch(() => commit('setJackpots', { error })),
            ],
            { root: true }
        );
    }),
    [_action.GET_JACKPOT_TICKET]({ commit }, ticketId) {
        return Vue.$http
            .get(`${pool.getJackpotTickets}/${ticketId}`)
            .then((response) => commit('setJackpotTicket', processJackPotTicket(response.data)))
            .catch((response) => commit('setMyBetsError', response && response.error));
    },
    [_action.ADD_JACKPOT_TICKET]({ state, dispatch, commit, rootGetters }, ticket) {
        const { poolId, poolName, selections, rows, price, tiebreakers } = ticket;
        const { jurisdictionId } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return Vue.$http
            .post(
                pool.addJackpotTicket,
                {
                    poolId,
                    selections,
                    tiebreakers,
                    uniqueTicketId: state.jackpot.uniqueTicketId,
                },
                { headers: { jurId: jurisdictionId } }
            )
            .then(({ data }) => {
                dispatch(auth.action.GET_BALANCE, { force: true }, { root: true });
                Vue.$gtm.query({
                    event: 'jackpot_ticket',
                    jackpot_id: poolId,
                    jackpot_name: poolName,
                    total_price: price,
                    ticket_amount: rows,
                });
                ticket.id = data.id;
                return ticket;
            })
            .catch((error) => {
                Vue.$gtm.query({
                    event: 'jackpot_failed',
                    reason: error.message || 'unknown',
                    price,
                    poolId,
                });
                commit('setJackpotError', helper.processErrorResponse(error, 'ui.jackpot.error.failedToBuyTicket'));
            });
    },
    [_action.SET_JACKPOT_ERROR]({ commit }, error) {
        commit('setJackpotError', error);
    },
    [_action.GENERATE_JACKPOT_UUID]({ commit }) {
        commit('generateJackpotUUID');
    },
    [_action.GET_SEARCH_RESULTS]: actionLoader(action.GET_SEARCH_RESULTS, ({ commit }, query) => {
        return Vue.$http
            .post(`${pricing.getSearchResult}`, { name: query })
            .then(({ data }) => data.Data || data)
            .then(({ events, competitions }) => {
                const Events = processSportsResponse(events, false);
                return {
                    ...Events,
                    groups: competitions,
                };
            })
            .then((data) => commit(_mutation.UPDATE_SPORTS, data))
            .catch((error) => console.error(`${action.GET_SEARCH_RESULTS} Response Error`, [error, query]));
    }),
    [_action.GET_JACKPOT_BETSLIPS]: actionLoader(action.GET_JACKPOT_BETSLIPS, ({ commit, rootGetters }, { skip = 0, take = 0 }) => {
        const { user } = rootGetters[platformGetter.GET_SETTINGS];
        return Vue.$http
            .get(`${pool.getJackpotTickets}?skip=${skip}&take=${take}&uuid=${user.userUuid}`)
            .then((response) => response.data)
            .then(({ tickets, hasMore }) => {
                commit('updateMyBets', {
                    items: tickets?.map((ticket) => processJackPotTicket(ticket)),
                    type: myBetsType.JACKPOT,
                    hasMore,
                });
            })
            .catch((error) => commit('setMyBetsError', error.message));
    }),
    [_action.GET_SETTLED_BETSLIPS]: actionLoader(action.GET_SETTLED_BETSLIPS, ({ commit, rootGetters }, { skip = 0, take = 0 }) => {
        const { user } = rootGetters[platformGetter.GET_SETTINGS];
        return Vue.$http
            .get(`${betslipEndpoints.getSettledBetslips}?skip=${skip}&take=${take}&uuid=${user.userUuid}`)
            .then((response) => response.data)
            .then(({ betslips, hasMore }) => {
                commit('updateMyBets', { items: betslips, type: myBetsType.SETTLED, hasMore });
            })
            .catch((error) => commit('setMyBetsError', error.message));
    }),
    [_action.GET_VIRTUAL_BETSLIPS]: actionLoader(action.GET_VIRTUAL_BETSLIPS, ({ commit, rootGetters }, { skip = 0, take = 0 }) => {
        const { user } = rootGetters[platformGetter.GET_SETTINGS];
        return Vue.$http
            .get(`${betslipEndpoints.getVirtualBetslips}?skip=${skip}&take=${take}&uuid=${user.userUuid}`)
            .then((response) => response.data)
            .then(({ betslips, hasMore }) => {
                commit('updateMyBets', { items: betslips, type: myBetsType.VIRTUAL, hasMore });
            })
            .catch((error) => commit('setMyBetsError', error.message || 'Generic error message'));
    }),
    [_action.GET_PENDING_BETSLIPS]: actionLoader(action.GET_PENDING_BETSLIPS, ({ commit, rootGetters }, { skip = 0, take = 0 }) => {
        const { user } = rootGetters[platformGetter.GET_SETTINGS];
        return Vue.$http
            .get(`${betslipEndpoints.getPendingBetslips}?take=${take}&skip=${skip}&uuid=${user.userUuid}`)
            .then(({ data }) => data.Data || data)
            .then(({ betslips, hasMore }) => {
                commit('updateMyBets', { items: betslips, type: myBetsType.PENDING, hasMore });
            })
            .catch((error) => commit('setMyBetsError', error.message));
    }),
    [_action.RESET_MY_BETS]({ commit }) {
        commit('resetMyBets');
    },
    [_action.GET_VIRTUAL_ROUNDS]: actionLoader(action.GET_VIRTUAL_ROUNDS, ({ commit }) => {
        commit(_mutation.SET_VIRTUAL_SPORTS_ERROR);
        return Vue.$http
            .get(pricing.getVirtualRounds)
            .then((response) => {
                commit(_mutation.SET_VIRTUAL_ROUNDS, response.data.gameRounds);
                commit(_mutation.SET_VIRTUAL_SEASONS, response.data.seasons);
            })
            .catch((error) => {
                commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, error.message || 'Generic error message');
            });
    }),
    [_action.GET_VIRTUAL_STANDINGS]: actionLoader(action.GET_VIRTUAL_STANDINGS, ({ commit, getters }, seasonId) => {
        commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, '');
        commit(_mutation.RESET_VIRTUAL_STANDINGS);

        return Vue.$http
            .get(`${pricing.getVirtualStandings}/${seasonId}`)
            .then((response) => {
                const selectedSeason = getters[_getter.GET_SELECTED_VIRTUAL_SEASON];
                if (selectedSeason.id === seasonId) {
                    commit(_mutation.SET_VIRTUAL_STANDINGS, response.data);
                }
            })
            .catch((error) => {
                const selectedSeason = getters[_getter.GET_SELECTED_VIRTUAL_SEASON];
                if (selectedSeason.id === seasonId) {
                    commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, error.message || 'Generic error message');
                } else {
                    console.error(`${action.GET_VIRTUAL_STANDINGS} Response Error`, [error]);
                }
            });
    }),
    [_action.GET_VIRTUAL_MATCHUPS]: actionLoader(action.GET_VIRTUAL_MATCHUPS, ({ commit }, params) => {
        commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, '');
        commit(_mutation.RESET_VIRTUAL_MATCHUPS);
        return Vue.$http
            .get(`${pricing.getVirtualMatchUps}/${params.seasonId}/${params.roundId}`)
            .then((response) => {
                commit(_mutation.SET_VIRTUAL_MATCHUPS, response.data);
            })
            .catch((error) => {
                commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, error.message || 'Generic error message');
            });
    }),
    [_action.GET_VIRTUAL_LIVE_ROUND]: actionLoader(action.GET_VIRTUAL_LIVE_ROUND, ({ commit }, roundId) => {
        commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, '');
        commit(_mutation.RESET_VIRTUAL_EVENTS, { roundStatus: ROUND_STATUS.BETTING_ACTIVE });
        return Vue.$http
            .get(`${pricing.getVirtualLiveRound}/${roundId}`)
            .then((response) => {
                commit(_mutation.SET_VIRTUAL_EVENTS, {
                    events: response.data,
                    roundStatus: ROUND_STATUS.BETTING_ACTIVE,
                });
            })
            .catch((error) => {
                commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, error.message || 'Generic error message');
            });
    }),
    [_action.GET_VIRTUAL_EVENTS_BY_ROUND_ID]: actionLoader(action.GET_VIRTUAL_EVENTS_BY_ROUND_ID, ({ commit }, roundId) => {
        commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, '');
        commit(_mutation.RESET_VIRTUAL_EVENTS, { roundStatus: ROUND_STATUS.BETTING_NOT_OPEN });
        return Vue.$http
            .get(`${pricing.getVirtualEventsByRoundId}/${roundId}`)
            .then(({ data }) => data.Data || data)
            .then(({ items }) => {
                const sortedEvents = sortMarkets(items);
                const updatedEvents = sortedEvents
                    .map((it) => {
                        return { ...it, additionalInfo: { ...it.additionalInfo, virtual: true } };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
                const leagues = Array(Object.values(VIRTUAL_LEAGUE_MAP).length).fill({});
                updatedEvents.forEach((match) => {
                    const leagueIndex = leagues.findIndex((it) => it?.league?.name?.includes(match.competition.name));
                    if (leagueIndex >= 0) {
                        leagues[leagueIndex].matches.push(match);
                    } else {
                        const leagueId = VIRTUAL_UPCOMING_LEAGUE_MAP[Number(match.region.id)];
                        const leagueIndex = Object.keys(VIRTUAL_LEAGUE_MAP).indexOf(leagueId?.toString());
                        leagues[leagueIndex] = {
                            league: {
                                id: leagueIndex + 1,
                                name: match.competition.name,
                            },
                            matches: [match],
                        };
                    }
                });
                commit(_mutation.SET_VIRTUAL_EVENTS, { events: leagues, roundStatus: ROUND_STATUS.BETTING_NOT_OPEN });
            })
            .catch((error) => {
                commit(_mutation.SET_VIRTUAL_SPORTS_ERROR, error.message || 'Generic error message');
            });
    }),
    [_action.CREATE_AUTO_CASHOUT_OFFER]: actionLoader(action.CREATE_AUTO_CASHOUT_OFFER, ({ commit }, payload) => {
        return Vue.$http
            .post(betslipEndpoints.autoCashoutOffer, payload)
            .then(({ data }) => {
                commit(_mutation.SET_AUTO_CASHOUT_OFFER, data);
                commit(_mutation.SET_AUTO_CASHOUT_OFFER_ERROR, null);
            })
            .catch(({ errorCode, statusCode, message, params }) => {
                const code = errorCode || statusCode;
                const isOfferOutOfRangeError = code === AUTO_CASHOUT_OFFER_OUT_OF_RANGE_ERROR;
                if (isOfferOutOfRangeError) {
                    const [minOffer, maxOffer] = params;
                    commit(_mutation.SET_AUTO_CASHOUT_OFFER, { maxOffer, minOffer });
                }
                commit(_mutation.SET_AUTO_CASHOUT_OFFER_ERROR, {
                    message,
                    isDead: AUTO_CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                    isLockedError: AUTO_CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                    isNetworkError: !code,
                    isGenericError: !AUTO_CASHOUT_OFFER_ERRORS.includes(code),
                    isOfferOutOfRangeError,
                });
            });
    }),
    [_action.GET_BETSLIP_AUTO_CASHOUT_OFFER]: actionLoader(action.GET_BETSLIP_AUTO_CASHOUT_OFFER, ({ commit }, { betslipId }) => {
        return Vue.$http
            .get(`${betslipEndpoints.autoCashoutOffer}/${betslipId}`)
            .then(({ data }) => {
                commit(_mutation.SET_AUTO_CASHOUT_OFFER, data);
                commit(_mutation.SET_AUTO_CASHOUT_OFFER_ERROR, null);
            })
            .catch(({ errorCode, statusCode, message }) => {
                const code = errorCode || statusCode;
                commit(_mutation.SET_AUTO_CASHOUT_OFFER_ERROR, {
                    message,
                    isDead: AUTO_CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                    isLockedError: AUTO_CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                    isNetworkError: !code,
                    isGenericError: !AUTO_CASHOUT_OFFER_ERRORS.includes(code),
                    isOfferOutOfRangeError: code === AUTO_CASHOUT_OFFER_OUT_OF_RANGE_ERROR,
                });
            });
    }),
    [_action.REQUEST_CASHOUT_OFFER]: actionLoader(action.REQUEST_CASHOUT_OFFER, ({ commit, dispatch, state }, { betslipId, amount }) => {
        const { pollingSeconds, requestTimestamp } = state.myBets.offerPolling;
        const isCashoutFetchAllowed = Date.now() - pollingSeconds * 1000 < requestTimestamp;
        if (deviceType.isPresto() && isCashoutFetchAllowed) {
            return Promise.resolve();
        }
        return Vue.$http
            .post(betslipEndpoints.requestCashoutOffer, { betslipId })
            .then(({ data }) => {
                commit(_mutation.SET_CASHOUT_OFFER_ERROR);
                commit(_mutation.SET_CASHOUT_OFFER, data);
                Vue.$gtm.query({
                    event: 'cashout',
                    cashout_amount: amount,
                    status: 'requested',
                });
            })
            .catch(({ errorCode, statusCode, message, payload }) => {
                const code = errorCode || statusCode;
                const { pollingEnabled, illegalSelections, polling } = payload || {};
                commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                    refresh: !pollingEnabled && CASHOUT_OFFER_ERRORS.includes(code),
                    isGenericError: !CASHOUT_OFFER_ERRORS.includes(code),
                    dead: CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                    message,
                    isLockedError: CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                    isNetworkError: !code,
                    ...(illegalSelections && { illegalSelections }),
                });
                if (pollingEnabled) {
                    commit(_mutation.UPDATE_CASHOUT_POLLING, {
                        pollingEnabled: !deviceType.isPresto(),
                        initialRequestLimit: polling.requestLimit,
                        requestTimestamp: Date.now(),
                        ...polling,
                    });
                    if (!deviceType.isPresto() && !CASHOUT_OFFER_NOT_CASHOUTABLE.includes(code)) {
                        setTimeout(() => {
                            dispatch(_action.REQUEST_CASHOUT_OFFER_AUTO, { betslipId });
                        }, polling.pollingSeconds * 1000);
                    }
                } else {
                    commit(_mutation.UPDATE_CASHOUT_POLLING, { pollingEnabled: false });
                    Vue.$gtm.query({
                        event: 'cashout_failed',
                        cashout_amount: amount,
                        reason: message || 'unknown',
                        code,
                    });
                }
            });
    }),
    [_action.REQUEST_CASHOUT_OFFER_AUTO]({ commit, dispatch, state }, { betslipId }) {
        const { requestLimit, pollingEnabled } = state.myBets.offerPolling || {};
        if (pollingEnabled && requestLimit > 0) {
            return Vue.$http
                .post(betslipEndpoints.requestCashoutOfferAuto, { betslipId })
                .then(({ data }) => {
                    commit(_mutation.UPDATE_CASHOUT_POLLING, { pollingEnabled: false });
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR);
                    commit(_mutation.SET_CASHOUT_OFFER, data);
                })
                .catch(({ errorCode, statusCode, message, payload }) => {
                    const code = errorCode || statusCode;
                    const { pollingEnabled, polling, illegalSelections } = payload || {};
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                        refresh: !pollingEnabled && CASHOUT_OFFER_ERRORS.includes(code),
                        isGenericError: !CASHOUT_OFFER_ERRORS.includes(code),
                        dead: CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                        message,
                        ...(illegalSelections && { illegalSelections }),
                        isLockedError: CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                        isNetworkError: !code,
                    });
                    if (code) {
                        commit(_mutation.UPDATE_CASHOUT_POLLING, {
                            pollingEnabled,
                            ...polling,
                            requestLimit: requestLimit - 1,
                        });
                        setTimeout(() => {
                            dispatch(_action.REQUEST_CASHOUT_OFFER_AUTO, { betslipId });
                        }, polling.pollingSeconds * 1000);
                    } else {
                        commit(_mutation.UPDATE_CASHOUT_POLLING, { pollingEnabled: false });
                    }
                });
        } else {
            commit(_mutation.UPDATE_CASHOUT_POLLING, { pollingEnabled: false });
        }
    },
    [_action.CONFIRM_CASHOUT_OFFER]: actionLoader(
        action.CONFIRM_CASHOUT_OFFER,
        ({ commit, dispatch }, { offerId, amountNet, betslipId }) => {
            return Vue.$http
                .post(betslipEndpoints.confirmCashoutOffer, { offerId })
                .then(({ data }) => data.Data || data)
                .then((data) => {
                    const { statusId, confirmed, pollingTimeoutSeconds } = data;
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR);
                    commit(_mutation.SET_CASHOUT_STATUS, {
                        statusId,
                        confirmed,
                        pollingCounter: pollingTimeoutSeconds,
                    });

                    if (confirmed) {
                        dispatch(auth.action.GET_BALANCE, { force: true }, { root: true });
                        Vue.$gtm.query({
                            event: 'cashout',
                            cashout_amount: amountNet,
                            status: 'accepted',
                        });
                    } else {
                        dispatch(_action.CHECK_CASHOUT_OFFER_STATUS, { statusId, betslipId, amount: amountNet });
                    }
                    return data;
                })
                .catch(({ errorCode, statusCode, payload, message }) => {
                    const code = errorCode || statusCode;
                    const { illegalSelections } = payload || {};
                    if (CASHOUT_CONFIRM_OFFER_RUN_POLLING_ERRORS.includes(code)) {
                        dispatch(_action.REQUEST_CASHOUT_OFFER, { betslipId, amount: amountNet });
                    }
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                        refresh: CASHOUT_OFFER_ERRORS.includes(code),
                        isGenericError: !CASHOUT_OFFER_ERRORS.includes(code),
                        dead: CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                        message,
                        ...(illegalSelections && { illegalSelections }),
                        isLockedError: CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                        isNetworkError: !code,
                    });

                    Vue.$gtm.query({
                        event: 'cashout_failed',
                        cashout_amount: amountNet,
                        reason: message || 'unknown',
                        code,
                    });
                });
        }
    ),
    [_action.CHECK_CASHOUT_OFFER_STATUS]: actionLoader(
        action.CHECK_CASHOUT_OFFER_STATUS,
        ({ dispatch, commit }, { statusId, betslipId, amount }) => {
            return Vue.$http
                .get(`${betslipEndpoints.statusCashoutOffer}/${statusId}`)
                .then(({ data }) => data.Data || data)
                .then(({ confirmed, pollingTimeoutSeconds }) => {
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR);
                    commit(_mutation.SET_CASHOUT_STATUS, {
                        statusId,
                        confirmed,
                        pollingCounter: pollingTimeoutSeconds,
                    });

                    if (confirmed) {
                        dispatch(auth.action.GET_BALANCE, { force: true }, { root: true });
                        dispatch(_action.GET_SINGLE_BETSLIP, betslipId);

                        Vue.$gtm.query({
                            event: 'cashout',
                            cashout_amount: amount,
                            status: 'accepted',
                        });
                        return;
                    }

                    if (!pollingTimeoutSeconds || pollingTimeoutSeconds <= 0) {
                        const errorMessage = Vue.$t('ui.cashout.confirmOfferFailed');
                        commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                            dead: false,
                            isGenericError: false,
                            refresh: false,
                            message: errorMessage,
                            isLockedError: false,
                            isNetworkError: false,
                        });

                        Vue.$gtm.query({
                            event: 'cashout_failed',
                            cashout_amount: amount,
                            reason: errorMessage,
                        });
                    } else if (!deviceType.isPresto()) {
                        setTimeout(() => {
                            dispatch(_action.CHECK_CASHOUT_OFFER_STATUS, { statusId, betslipId, amount });
                        }, CASHOUT_STATUS_CHECK_TIMEOUT);
                    }
                })
                .catch((error) => {
                    const { errorCode, statusCode, payload, message } = error;
                    const code = errorCode || statusCode;
                    const { illegalSelections } = payload || {};
                    commit(_mutation.SET_CASHOUT_STATUS);
                    commit(_mutation.SET_CASHOUT_OFFER_ERROR, {
                        refresh: CASHOUT_OFFER_ERRORS.includes(code),
                        isGenericError: !CASHOUT_OFFER_ERRORS.includes(code),
                        dead: CASHOUT_OFFER_DEAD_ERRORS.includes(code),
                        message,
                        ...(illegalSelections && { illegalSelections }),
                        isLockedError: CASHOUT_OFFER_LOCKED_ERRORS.includes(code),
                        isNetworkError: !code,
                    });

                    Vue.$gtm.query({
                        event: 'cashout_failed',
                        cashout_amount: amount,
                        reason: message || 'unknown',
                        code,
                    });
                    throw error;
                });
        }
    ),
    [_action.UPDATE_SAVED_EVENT_FILTERS]({ commit }, { categoryId, filters }) {
        commit(_mutation.SET_SAVED_EVENT_FILTERS, { categoryId, filters });
    },
};

const _mutation = getLocalTypes(mutation);

const mutations = {
    [_mutation.SET_STATS_PERFORM](state, data) {
        state.sports.statsPerform = data;
    },
    [_mutation.SET_IS_LIVE_STREAM_AVAILABLE](state, data) {
        state.isLiveStreamAvailable = data;
    },
    [_mutation.SET_SPORT_RADAR_JWT](state, data) {
        state.sports.srJWT = data;
    },
    [_mutation.SET_SPORT_TYPE](state, isVirtualSport) {
        if (isVirtualSport && state.ui.sportType !== sportType.VIRTUAL) {
            state.ui.sportType = sportType.VIRTUAL;
        } else if (!isVirtualSport && state.ui.sportType !== sportType.SPORT) {
            state.ui.sportType = sportType.SPORT;
        }
    },
    [_mutation.UPDATE_SPORTS](state, newValue) {
        newValue.error = undefined;
        state.sports = { ...state.sports, ...newValue };
    },
    [_mutation.SET_IN_PLAY_DETAILS](state, newValue) {
        state.myBets.betslip.selections = state.myBets.betslip.selections.map((item) => {
            const { scoreboard } = newValue.find((i) => parseFloat(i.id) === item.selection.event.id) || {};

            return {
                ...item,
                inPlayDetails: scoreboard ? { ...scoreboard, startCounter: new Date() } : { ...item.inPlayDetails },
            };
        });
    },
    [_mutation.SET_SINGLE_EVENT](state, payload) {
        const { eventId, marketId, event, markets } = payload;
        const isMarketType = Object.values(marketTypeCategory).includes(marketId);

        isMarketType && Vue.delete(state.sports.singleEvent[marketId], eventId);
        Vue.delete(state.sports.singleEvent.list, eventId);
        Vue.delete(state.sports.singleEvent.markets, eventId);

        isMarketType && Vue.set(state.sports.singleEvent[marketId], eventId, event.markets);
        Vue.set(state.sports.singleEvent.list, eventId, event);
        Vue.set(state.sports.singleEvent.markets, eventId, markets);

        state.isLiveStreamAvailable = getEventWidget(event, {
            wantedWidgetType: widgetType.STAT_PERFORM_LIVE_STREAM,
            defaultValue: undefined,
        });
    },
    setAfterBetPlaced(state, value) {
        state.ui.afterBetPlaced = value;
    },
    appendUpcoming(state, value) {
        state.sports.events = state.sports.events.concat(value);
    },
    [_mutation.REMOVE_UPCOMING_EVENTS](state, amount) {
        state.sports.remainingEventIds.splice(0, amount);
    },
    [_mutation.CONTAINS_HOT](state, value) {
        Vue.set(state, 'containsHot', value);
    },
    setMyBetsError(state, error) {
        state.myBets.error = error;
    },
    resetMyBets(state) {
        state.myBets = { ...MY_BETS_STATE };
    },
    updateMyBets(state, { type = null, items = [], hasMore = false }) {
        state.myBets.error = undefined;
        const targetType = state.myBets[type];
        state.myBets[type] = {
            items: [...((targetType && targetType.items) || []), ...items],
            hasMore,
        };
    },
    setJackpotTicket(state, newValue) {
        state.myBets.error = undefined;
        state.myBets.betslip = newValue;
    },
    setSingleBetslip(state, value) {
        state.myBets.error = undefined;
        state.myBets.betslip = value;
    },
    setSingleBetslipEvents(state, value) {
        state.myBets.error = undefined;
        state.myBets.events = value;
    },
    setJackpots(state, value) {
        const selected = state.jackpot.selected;
        const uniqueTicketId = state.jackpot.uniqueTicketId;
        state.jackpot = {
            ...JACKPOT_STATE,
            ...{ selected },
            ...{ uniqueTicketId },
            ...value,
        };
    },
    setJackpotError(state, error) {
        state.jackpot.error = error;
    },
    resetJackpotError(state, error) {
        state.jackpot.error = null;
    },
    setJackpotSelected(state, selected) {
        state.jackpot.selected = selected;
    },
    setSportsError(state, { error, message }) {
        state.sports.error = message;
        state.sports.errorCode = error.errorCode;
    },
    setSingleEventError(state, payload) {
        const message = payload.message;
        state.sports.singleEvent.errors = state.sports.singleEvent.errors.concat({ ...payload, message });
    },
    [_mutation.RESET_SINGLE_EVENT_ERROR](state) {
        state.sports.singleEvent.errors = [];
    },
    [_mutation.RESET_SPORTS_ERROR](state) {
        state.sports.error = null;
    },
    [_mutation.SET_VIRTUAL_ROUNDS](state, rounds) {
        state.virtualSports.rounds = rounds;
    },
    [_mutation.SET_VIRTUAL_SEASONS](state, seasons) {
        state.virtualSports.seasons = seasons;
    },
    [_mutation.SET_VIRTUAL_EVENTS](state, { events, roundStatus }) {
        state.virtualSports.events[roundStatus] = events;
    },
    [_mutation.RESET_VIRTUAL_EVENTS](state, { roundStatus }) {
        state.virtualSports.events[roundStatus] = {};
    },
    [_mutation.SET_VIRTUAL_STANDINGS](state, { season = {}, standings = [] }) {
        state.virtualSports.season = season;
        state.virtualSports.standings = standings;
    },
    [_mutation.RESET_VIRTUAL_STANDINGS](state) {
        state.virtualSports.standings = null;
    },
    [_mutation.SET_VIRTUAL_SEASON](state, season = {}) {
        state.virtualSports.season = season;
    },
    [_mutation.SET_VIRTUAL_MATCHUPS](state, matchups) {
        state.virtualSports.matchups = matchups;
    },
    [_mutation.RESET_VIRTUAL_MATCHUPS](state) {
        state.virtualSports.matchups = { ...MATCHUPS_STATE };
    },
    [_mutation.SET_VIRTUAL_SPORTS_ERROR](state, error = null) {
        state.virtualSports.error = error;
    },
    [_mutation.SET_CASHOUT_OFFER](state, cashout) {
        state.myBets.betslip = {
            ...state.myBets.betslip,
            cashout,
        };
    },
    [_mutation.SET_IS_CONFIRM_CASHOUT_READY](state, value) {
        state.myBets.betslip = {
            ...state.myBets.betslip,
            isConfirmCashOutReady: value,
        };
    },
    [_mutation.SET_IS_CASHOUT_REFRESHING](state, value) {
        state.myBets.betslip = {
            ...state.myBets.betslip,
            isCashOutRefreshing: value,
        };
    },
    [_mutation.CLEAR_AUTO_CASHOUT_OFFER](state) {
        state.myBets.autoCashOut = null;
    },
    [_mutation.CLEAR_AUTO_CASHOUT_OFFER_ERROR](state) {
        state.myBets.autoCashOutOfferError = {};
    },
    [_mutation.SET_AUTO_CASHOUT_OFFER](state, autoCashOutData) {
        state.myBets.autoCashOut = { ...state.myBets.autoCashOut, ...autoCashOutData };
    },
    [_mutation.SET_CASHOUT_OFFER_ERROR](state, error) {
        state.myBets.offerError = { ...MY_BETS_STATE.offerError, ...(error && Object.keys(error).length && error) };
    },
    [_mutation.SET_AUTO_CASHOUT_OFFER_ERROR](state, error) {
        state.myBets.autoCashOutOfferError = { ...MY_BETS_STATE.autoCashOutOfferError, ...(error && Object.keys(error).length && error) };
    },
    [_mutation.UPDATE_CASHOUT_POLLING](state, payload = {}) {
        state.myBets.offerPolling = { ...state.myBets.offerPolling, ...payload };
    },
    [_mutation.SET_CASHOUT_STATUS](state, status) {
        state.myBets.offerStatus = status || { ...MY_BETS_STATE.offerStatus };
    },
    [_mutation.GENERATE_JACKPOT_UUID](state) {
        state.jackpot.uniqueTicketId = helper.createUUID();
    },
    [_mutation.TOGGLE_STATISTIC](state) {
        state.isStatisticOpen = !state.isStatisticOpen;
    },
    [_mutation.UPDATE_SINGLE_EVENT_SCOREBOARD](state, { eventId, scoreboardResponse }) {
        Vue.set(state.sports.singleEvent.list[eventId], 'scoreboard', scoreboardResponse);
    },
    [_mutation.UPDATE_SINGLE_EVENT_ODDS](state, { eventId, odds }) {
        const markets = state.sports.singleEvent.markets[eventId];
        for (const market of markets) {
            market.prices = market.prices.map((p) => {
                const { price } = odds.find((odd) => Number(odd.id) === Number(p.id)) || {};
                if (price) p.price = price;
                return p;
            });
        }
        Vue.set(state.sports.singleEvent.list[eventId], 'odds', odds);
    },
    [_mutation.SET_SAVED_EVENT_FILTERS](state, filterData) {
        if (filterData) {
            Vue.set(state.ui.savedEventFilters, filterData.categoryId, filterData.filters);
        } else {
            state.ui.savedEventFilters = {};
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        betslip,
    },
};
