<template>
    <div class="league-shortcut" v-if="!isPresto && tournaments && tournaments.length">
        <LeagueShortcutBox
            v-for="(entry, index) in manualEntries"
            :key="`tournament-entry-${entry.name}-${index}`"
            :name="entry.name"
            :event-count="entry.count"
            :link="entry.link"
        >
            <template slot="icon">
                <SvgIcon :iconId="entry.iconId" :class="`icon icon-size-medium ${entry.iconId}`" />
            </template>
        </LeagueShortcutBox>
        <LeagueShortcutBox
            v-for="tournament in tournaments"
            :key="`tournament-${tournament.id}`"
            :name="tournament.name"
            :event-count="tournament.eventCount"
            :link="{ name: routeGroupName, params: { id: tournament.id } }"
        >
            <template slot="icon">
                <ImageIcon
                    v-if="tournament.region"
                    class="icon-size-medium"
                    :src="tournament.url || (tournament.region && `img/flags/${tournament.regionSlug.toLowerCase()}.png`)"
                    :altSrc="tournament.regionSlug && `img/flags/${tournament.parentRegionSlug}.png`"
                />
            </template>
        </LeagueShortcutBox>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { sport } from '@agi.packages/sport';
import LeagueShortcutBox from '@/components/content/LeagueShortcutBox.vue';
import { routeName } from '@/router/const-name';
import { deviceType } from '@/modules/core';
import { getter as generalGetter } from '@/store/store';

export default {
    name: 'LeagueShortcut',
    components: { LeagueShortcutBox },
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        itemsToShow: Number,
    },
    computed: {
        ...mapState({
            currentTournaments: 'currentTournaments',
        }),
        ...mapGetters({
            eventsCount: sport.getter.EVENTS_COUNT_PER_TYPE,
            moreEventsCount: sport.getter.GET_SPORT_EVENTS_COUNTER,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
        }),
        routeGroupName() {
            return routeName.GROUP;
        },
        isPresto() {
            return deviceType.isPresto();
        },
        content() {
            const { content } = this.data || {};
            return content || {};
        },
        tournaments() {
            return this.currentTournaments.slice(0, this.content.leaguesToShow);
        },
        manualEntries() {
            const itemMap = {
                Live: {
                    name: this.$t('menus.live'),
                    count: this.eventsCount.liveEventsCount,
                    iconId: 'icon-live',
                    link: { name: routeName.LIVE },
                },
                Popular: {
                    name: this.$t('ui.popular.popularEvents'),
                    count: 0, // TODO: now the backend does not send us this counter
                    iconId: 'icon-popular',
                    link: { name: routeName.POPULAR },
                },
                Boosted: {
                    name: this.$t('ui.common.bestOdds'),
                    count: this.eventsCount.boostedEventsCount[this.currentCategoryId],
                    iconId: 'icon-best-odds',
                    link: { name: routeName.BOOSTED_EVENTS },
                },
                Upcoming: {
                    name: this.$t('menus.upcoming'),
                    count: (this.moreEventsCount && this.moreEventsCount[this.currentCategoryId]) || 0,
                    iconId: 'icon-clock',
                    link: { name: routeName.UPCOMING },
                },
            };
            return this.content.sportItems
                .filter(({ SportItem }) => !!SportItem && itemMap[SportItem].count)
                .map(({ SportItem }) => itemMap[SportItem]);
        },
    },
};
</script>

<style lang="scss" scoped>
.league-shortcut {
    display: flex;
    flex-direction: row;
    overflow: auto;
    margin: 8px 0;
    padding: 0 10px;
    .simple-page & {
        padding: 0;
    }
    @include hide_scrollbar;

    .icon-live {
        fill: $game-status-icon-fill;
        color: $game-status-icon-fill;
        margin-left: 0;
    }
}
</style>
